import React from 'react';
import {Link} from 'react-router-dom';
import {
  AppBar,
  Toolbar
} from '@material-ui/core';
import logo from '../images/Logo.png';

const DefaultTopBar =(props)=>{
  return(
    <AppBar position='static' style={{background: 'transparent'}}>
      <Toolbar>
        <Link to='/' style={{textDecoration:'none'}}>
          <img src={logo} alt={logo} style={{height:'40px',width:'auto'}}/>
        </Link>
      </Toolbar>
    </AppBar>
  )
}

export default DefaultTopBar;
