import React, { Component } from 'react';
import CheckOutView from './CheckOutView';
import {Elements,StripeProvider} from 'react-stripe-elements';
import ProcessingView from './ProcessingView';
import SubscriptionConfirm from './SubscriptionConfirm';
import {Redirect,withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {createSubscription,createProfile} from './subscription';
import FreeView from './FreeView';
import {initUser} from '../user/user';
import TrialView from './TrialView';

const stripeApiKey = process.env.REACT_APP_STRIPE_API_KEY;

const defaultPlan = process.env.REACT_APP_BASIC_PLAN_ID;
const defaultSelection = 'Basic: $11.00/mo';
const defaultLimit = 15;

const certPlan = process.env.REACT_APP_CERT_PLAN_ID;
const premiumPlan = process.env.REACT_APP_PREMIUM_PLAN_ID;

class CheckOutContainer extends Component{

  state={
    selection: defaultSelection,
    name: '',
    street: '',
    unit : '',
    city : '',
    state : '',
    email : '',
    password: '',
    company : '',
    submitted: false,
    subscribed: false,
    plan: defaultPlan,
    error: '',
    url:'',
    txLimit:defaultLimit,
    id:'default'
  }

  handleChange= name => event =>{
    this.setState({
      [name]: event.target.value,
    });
  }

  handleError=()=>{
    this.setState({
      submitted: false,
      error: 'There was an error processing your card. Please try again.'
    })
  }

  handleFreeSubmit=async()=>{
    this.setState({submitted: true, error: ''});
    try{
      const existing = await this.props.auth.fetchSignInMethodsForEmail(this.state.email);
      if(!existing.length){
        await this.props.auth.createUserWithEmailAndPassword(this.state.email,
        this.state.password);
        const user = this.props.auth.currentUser;
        const token = await user.getIdToken();
        if(token){
          const customerId = '';
          const subscriptionId = '';
          const planId = this.state.plan;
          await createProfile(
            token,
            customerId,
            subscriptionId,
            planId,
            this.state.name,
            this.state.company,
            this.state.txLimit,
            this.state.url
          );
          this.props.initUser(token);
        }
      }
    }catch(err){
      alert(err);
    }
  }

  handleSubmit=async(paymentMethod)=>{
    this.setState({submitted: true, error: ''});
    try{
      const existing = await this.props.auth.fetchSignInMethodsForEmail(this.state.email);
      if(!existing.length){
        const result = await createSubscription(this.state.email,paymentMethod,this.state.plan);
        if(result.error){
          this.handleError();
          console.log(result.error);
        }else{
          if(result.data.status === 'requires_action'){
            this.handleError();
            console.log(result);
          }else{
            //success
            await this.props.auth.createUserWithEmailAndPassword(this.state.email,
            this.state.password);
            const user = this.props.auth.currentUser;
            const token = await user.getIdToken();
            if(token){
              //create profile with subscription data
              const customerId = result.data.customer;
              const subscriptionId = result.data.id;
              const planId = result.data.plan.id;
              await createProfile(
                token,
                customerId,
                subscriptionId,
                planId,
                this.state.name,
                this.state.company,
                this.state.txLimit,
                this.state.url
              );
              this.props.initUser(token);
            }
          }
        }
      }else{
        this.setState({submitted: false, error: 'Email already in use'})
      }
    }catch(err){
      alert(err);
    }
  }

  componentDidMount=()=>{
    const selection = this.props.match.params.id ? this.props.match.params.id : null;
    if(selection === 'basic'){
      this.setState({
        selection: defaultSelection,
        plan: defaultPlan,
        txLimit: 15,
        id: selection
      })
    }else if(selection === 'cert_sign_agent'){
      this.setState({
        selection: 'Certified Signing Agent: $11.00/mo',
        plan: certPlan,
        txLimit: 15,
        id: selection
      })
    }else if(selection === 'premium'){
      this.setState({
        selection: 'Premium: $19.00/mo',
        plan: premiumPlan,
        txLimit: 50,
        id: selection
      })
    }else if(selection === 'free'){
      this.setState({
        selection: 'Free: $0/mo',
        plan: 'free',
        txLimit: 0,
        id: selection
      })
    }else if(selection === 'trial'){
      this.setState({
        selection: 'Trial: $0/mo',
        plan: 'trial',
        txLimit: 50,
        id: selection
      })
    }
  }

  render(){

    if(this.props.token){
      return <Redirect exact to={'/confirmation/'+this.state.id}/>
    }

    if(this.state.plan === 'free'){
      return(
        <FreeView
        handleChange={this.handleChange}
        handleSubmit={this.handleFreeSubmit}
        state={this.state}
        error={this.state.error}
        />
      )
    }

    if(this.state.plan === 'trial'){
      return(
        <TrialView
        handleChange={this.handleChange}
        handleSubmit={this.handleFreeSubmit}
        state={this.state}
        error={this.state.error}
        />
      )
    }

    return(
      <StripeProvider apiKey={stripeApiKey}>
        {this.state.subscribed
          ? <SubscriptionConfirm/>
          : this.state.submitted
          ? <ProcessingView/>
          :<Elements>
            <CheckOutView
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            state={this.state}
            error={this.state.error}
            />
          </Elements>
        }
      </StripeProvider>
    )
  }
}

const mapStateToProps=(state)=>{
  return{
    token:state.app.token
  }
}

const mapDispatchToProps={
  initUser
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(CheckOutContainer));
