const style={
  documentLabel:{
    marginTop:'20px'
  },
  documentHeader:{
    display:'flex',
    alignItems:'center',
    width:'100%'
  },
  previewStyle:{
    display: 'inline',
    width: '800px',
    height:'600px',
  }
}

export default style;
