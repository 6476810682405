import React, { Component } from 'react';
import VerifyView from './VerifyView';
import SuccessView from './SuccessView';
import NotFoundView from './NotFoundView';
import UnmatchedView from './UnmatchedView';
import MetaMaskDialog from '../notification/MetaMaskDialog';
import {Redirect,withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import shajs from 'sha.js';

import RemotaryABI from '../contract/RemotaryABI';
//dev
const devAddress = process.env.REACT_APP_DEV_ADDRESS;
const address = process.env.REACT_APP_CONTRACT_ADDRESS;

class VerifyContainer extends Component{
  state={accepted:[],rejected:[],email:'',fileHash:'',contractHash:'',
    contractEmail:'',contractPublicKey:'',contractTimeStamp:'',
    notFound:false,emailMatch:false,queryComplete:false,dialog: false}

  clearState=()=>{
    this.setState({accepted:[],rejected:[],email:'',fileHash:'',
      contractEmail:'',contractPublicKey:'',contractTimeStamp:'',
      notFound:false,emailMatch:false,queryComplete:false});
  }

  queryContract=async()=>{
    const remotary = new this.props.web3.eth.Contract(RemotaryABI);
    remotary.options.address = this.props.network === 'rinkeby' ? devAddress : address;
    const data = await remotary.methods.verify(this.state.fileHash).call();
    this.setState({contractEmail:data[0],contractPublicKey:data[1],
      contractTimeStamp:data[2],queryComplete:true},function(){
      if(data[2] === 0){
        this.setState({notFound:true});
      }
      if(this.state.email === this.state.contractEmail){
        this.setState({emailMatch:true});
      }
    }.bind(this))
  }

  fileToString = (file)=> {
    return new Promise((resolve, reject)=>{
      const fileReader = new FileReader();
      fileReader.onerror = (error)=>{
        fileReader.abort();
        reject(error);
      }
      fileReader.onloadend=()=>{
        resolve(fileReader.result);
      }
      fileReader.readAsDataURL(file);
    });
  }

  fileToBuffer =(file)=>{
    return new Promise((resolve, reject)=>{
      const fileReader = new FileReader();
      fileReader.onerror = (error)=>{
        fileReader.abort();
        reject(error);
      }
      fileReader.onloadend=()=>{
        resolve(fileReader.result);
      }
      fileReader.readAsArrayBuffer(file);
    });
  }

  processFile = async()=>{
    if(this.state.accepted.length>0){
      try{
        var arrayBuffer = await this.fileToBuffer(this.state.accepted[0]);
        const buffer = await Buffer.from(arrayBuffer);
        const hash = shajs('sha256').update(buffer).digest('hex');
        console.log(hash)
        if(hash){
          this.setState({fileHash:hash})
        }
      }catch(error){
        console.log(error);
      }
    }
  }

  onDropAccepted = async(accepted)=>{
    this.setState({accepted:accepted,rejected:[]},function(){
      this.processFile();
    }.bind(this));
  }

  onDropRejected = (rejected)=>{
    this.setState({rejected:rejected})
  }

  setEmail=(email)=>{
    this.setState({email:email},function(){
      this.queryContract();
    }.bind(this));
  }

  componentDidMount=()=>{
    if(!window.ethereum){
      this.setState({dialog:true});
    }
  }

  render(){

    const acceptedTypes = 'application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain';
    const outputView = this.state.notFound ? <NotFoundView fileHash={this.state.fileHash} clearState={this.clearState}/> :
    this.state.emailMatch ? <SuccessView contractEmail={this.state.contractEmail} contractHash={this.state.fileHash}
    contractPublicKey={this.state.contractPublicKey} contractTimeStamp={this.state.contractTimeStamp} clearState={this.clearState}/> :
    <UnmatchedView contractEmail={this.state.contractEmail} contractHash={this.state.fileHash}
    contractPublicKey={this.state.contractPublicKey} contractTimeStamp={this.state.contractTimeStamp} email={this.state.email}
    clearState={this.clearState}/>;

    if(!this.props.token){
      return <Redirect exact to='/login'/>
    }

    return(
      <div>
        {this.state.queryComplete ?
          outputView
        :
          <VerifyView accepted={this.state.accepted} rejected={this.state.rejected}
          onDropAccepted={this.onDropAccepted} onDropRejected={this.onDropRejected}
          acceptedTypes={acceptedTypes} fileHash={this.state.fileHash} setEmail={this.setEmail}/>
        }
        <MetaMaskDialog open={this.state.dialog} handleClose={()=>{}}/>
      </div>
    )
  }
}

const mapStateToProps=(state)=>{
  return{
    token:state.app.token,
    myDocuments: state.documents.myDocuments,
    shared: state.documents.shared,
    profile: state.app.profile
  }
}

const mapDispatchToProps={

}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(VerifyContainer));
