import React, {Component} from 'react';
import style from '../styles/documentStyle';
import {
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import {fetchFile} from './document';

// const host = 'https://storage.cloud.google.com/';
// const project = process.env.REACT_APP_STORAGE_BUCKET;

class DocumentView extends Component{

  handleFetch=async(doc)=>{
    const url = doc.downloadUrl ? doc.downloadUrl : await fetchFile(doc.name);
    window.open(url);
  }

  render(){
    const {doc,selectDocument,openShare,user} = this.props;
    const date = doc.timestamp ? new Date(doc.timestamp).toLocaleDateString() : 'None';
    // const fileName = doc.name && encodeURIComponent(doc.name.trim());
    // const docUrl = doc.name ? host+project+'/'+fileName : '/';

    return(
      <div style={style.myDocumentsFrame}>
        <div style={style.documentHeader}>
          <IconButton
          onClick={()=>{selectDocument(null)}}
          >
            <BackIcon/>
          </IconButton>
          <Button
          onClick={()=>{this.handleFetch(doc)}}
          style={{marginLeft:'auto'}}
          >
          download
          </Button>
          {(user && user.uid === doc.ownerUid) &&
            <Button
            variant='contained'
            color='primary'
            style={{marginLeft:'20px'}}
            onClick={openShare}
            >
            Sharing
            </Button>
          }
        </div>
        <div>
        <Typography variant='h5' style={style.documentLabel}>Name</Typography>
        <Typography variant='h6'>{doc.name ? doc.name : 'None'}</Typography>
        <Typography variant='h5' style={style.documentLabel}>Date</Typography>
        <Typography>{date}</Typography>
        <Typography variant='h5' style={style.documentLabel}>Owner</Typography>
        <Typography>{doc.ownerEmail ? doc.ownerEmail : 'None'}</Typography>
        <Typography variant='h5' style={style.documentLabel}>Uploader Eth Public Key</Typography>
        <Typography>{doc.publicKey ? doc.publicKey : 'None'}</Typography>
        </div>
      </div>
    )
  }
}

export default DocumentView;
