import React, { Component } from 'react';
import {Redirect,withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import MyDocumentsView from './MyDocumentsView';
import DocumentContainer from './DocumentContainer';

class MyDocumentsContainer extends Component{

  state={
    selectedDocument: null
  }

  selectDocument=(file)=>{
    this.setState({selectedDocument:file});
  }

  componentDidUpdate=(prevProps)=>{
    if(JSON.stringify(prevProps.myDocuments) !== JSON.stringify(this.props.myDocuments)){
      if(this.state.selectedDocument){
        const doc = this.props.myDocuments.find(item => item.uid === this.state.selectedDocument.uid);
        if(doc){
          this.setState({selectedDocument: doc});
        }
      }
    }
  }

  render(){
    if(!this.props.token){
      return <Redirect exact to='/login'/>
    }

    return(
      this.state.selectedDocument ?
      <DocumentContainer
      doc={this.state.selectedDocument}
      selectDocument={this.selectDocument}
      />
      :
      <MyDocumentsView
      myDocuments={this.props.myDocuments}
      selectDocument={this.selectDocument}
      title={'Shared By Me'}
      />
    )
  }
}

const mapStateToProps=(state)=>{
  return{
    token: state.app.token,
    myDocuments: state.documents.myDocuments,
  }
}

const mapDispatchToProps={

}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(MyDocumentsContainer));
