import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Check from '@material-ui/icons/Check';
import Icon from '@material-ui/core/Icon';
import DocumentView from './DocumentView';

class SuccessView extends Component{

  render(){
    const outerStyle={
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
    }

    const lineStyle={
      marginTop:'20px'
    }
    return(
      <section style={outerStyle}>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:'20px'}}>
          <Icon><Check style={{color:'green'}}/></Icon>
          <Typography style={{fontSize:'150%',marginLeft:'20px'}}>Verification Success</Typography>
        </div>
        <Typography style={lineStyle}>Fetched Document:</Typography>
        <DocumentView {...this.props}/>
        <Typography style={lineStyle}>Verify another document</Typography>
        <Button style={lineStyle} onClick={this.props.clearState} variant='contained'>Back</Button>
      </section>
    )
  }
}

export default SuccessView;
