import React, { Component } from 'react';
import {Link,withRouter} from 'react-router-dom';
import{
  Typography,
} from '@material-ui/core';
import {connect} from 'react-redux';

const link={
  textDecoration:'none'
}
const container={
  margin:'120px 150px 100px 5%'
}
const item={
  marginBottom:'40px',
  fontSize:'16px'
}

class SideBar extends Component{

  render(){
    return(
      <div style={container}>
        <Link style={link} to='/all'>
          <Typography style={item}>All Documents</Typography>
        </Link>
        <Link style={link} to='/myDocuments'>
          <Typography style={item}>Shared By Me</Typography>
        </Link>
        <Link style={link} to='/shared'>
          <Typography style={item}>Shared With Me</Typography>
        </Link>
        {
          this.props.disabled &&
          <a href='https://safeboxsigning.com/safebox-instructions/' style={link}>
            <Typography style={item}>Add Blockchain Security</Typography>
          </a>
        }
      </div>
    )
  }
}

const mapStateToProps=(state)=>{
  return{
    profile: state.app.profile,
    monthlyTx: state.documents.monthlyTx
  }
}

const mapDispatchToProps={

}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(SideBar));
