import{
  setProfile
} from '../redux/actions/appActions';

const url = process.env.REACT_APP_EMAIL_URL;

const fetchMyProfile = async(token,dispatch)=>{
  try{
    const result = await fetch(url + '/myProfile',{
      headers:{
        'Authorization':token
      }
    });
    if(!result.ok){
      console.log(result);
    }
    let jsonResult = await result.json();
    dispatch(setProfile(jsonResult.data));
    return jsonResult.data;
  }catch(err){
    console.log(err);
    return null;
  }
}

export const unsubscribe = (token,subscriptionId)=>{
  return async(dispatch)=>{
    try{
      const result = await fetch(url + '/unsubscribe',{
        method:'POST',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization':token
        },
        body:JSON.stringify({
          subscriptionId:subscriptionId
        })
      });
      if(!result.ok){
        console.log(result);
      }
      let jsonResult = await result.json();
      if(jsonResult.data){
        dispatch(setProfile(jsonResult.data));
      }
      return jsonResult;
    }catch(err){
      console.log(err);
      return null;
    }
  }
}

export const initUser = (token)=>{
  return async(dispatch, getState)=>{
    fetchMyProfile(token, dispatch);
  }
}
