import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Check from '@material-ui/icons/Check';
import Icon from '@material-ui/core/Icon';
import Clear from '@material-ui/icons/Clear';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import {Link} from 'react-router-dom';

class StatusView extends Component{

  render(){
    const outerStyle={
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
    }

    const notification={
      display:'flex',
      flexDirection:'row',
      alignItems:'center',
      marginTop:'20px'
    }

    const paperStyle={
      width: '600px',
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      marginTop:'20px',
      padding:'10px'
    }

    const disabled = this.props.network === 'disconnected';
    const blockchainSelected = this.props.blockchainSelected;

    return(
      <section style={outerStyle}>
        {this.props.status === 'Complete' ?
        <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
          <div style={notification}>
            <Icon><Check style={{color:'green'}}/></Icon>
            <Typography style={{fontSize:'150%',marginLeft:'20px'}}>Upload Success</Typography>
          </div>
          <Paper style={paperStyle}>
            <Typography style={{marginTop:'10px',fontSize:'120%'}}>Transaction Hash:</Typography>
            <Typography style={{marginTop:'10px'}}>{this.props.txHash}</Typography>
          </Paper>
          <Link to='/myDocuments' style={{textDecoration:'none'}}>
            <Button style={{marginTop:'20px'}} variant='contained'>My Documents</Button>
          </Link>
        </div>
        : this.props.status === 'Error' ?
        <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
          <div style={notification}>
            <Icon><Clear style={{color:'red'}}/></Icon>
            <Typography style={{fontSize:'150%',marginLeft:'20px'}}>Problem with blockchain transaction</Typography>
          </div>
          <Typography style={{marginTop:'20px'}}>
          Either the document hash has been uploaded before or the transaction was rejected by the user.
          </Typography>
          <Link to='/myDocuments' style={{textDecoration:'none'}}>
            <Button style={{marginTop:'40px'}} variant='contained' color='primary'>My Documents</Button>
          </Link>
        </div>
        :
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'40px'}}>
          <Typography style={{marginTop:'10px',fontSize:'120%'}}>Document uploaded to SAFEBOX.</Typography>
          {
            blockchainSelected &&
            <Typography style={{marginTop:'10px',fontSize:'120%'}}>Confirm Blockchain transaction on the Metamask pop-up.</Typography>
          }
          <Link to='/myDocuments' style={{textDecoration:'none'}}>
            <Button style={{marginTop:'40px'}} variant='contained' color='primary'>My Documents</Button>
          </Link>
          <Hidden smDown>
          {disabled &&
            <div style={{marginTop:'40px',display:'flex',flexDirection:'column',alignItems:'center'}}>
              <Typography style={{marginTop:'10px',fontSize:'120%'}}>Would you like to add Blockchain security to your next transaction?</Typography>
              <a href='https://safeboxsigning.com/safebox-instructions/'>
                <Typography variant='h6' style={{marginTop:'20px'}}>Click here to get started</Typography>
              </a>
            </div>
          }
          </Hidden>
        </div>
        }
      </section>
    )
  }
}

export default StatusView;
