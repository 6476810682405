import{
  SET_MY_DOCUMENTS,
  SET_SHARED,
  SET_MONTHLY_TX
} from '../action_types/documentActionTypes';

const initialState={
  myDocuments:[],
  shared:[],
  monthlyTx: 0
}

const documentReducer = (state = initialState,action)=>{
  switch(action.type){
    case SET_MY_DOCUMENTS:
      return{
        ...state,
        myDocuments: action.myDocuments
      }
    case SET_SHARED:
      return{
        ...state,
        shared: action.shared
      }
    case SET_MONTHLY_TX:
      return{
        ...state,
        monthlyTx: action.monthlyTx
      }
    default:
      return state
  }
}

export default documentReducer;
