const style={
  selector:{
    marginTop:'20px',
  },
  header:{
    marginBottom:'20px'
  },
  container:{
    width:'90%',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    padding:'20px 40px'
  },
  tile:{
    width:'200px',
    height:'300px',
    padding:'20px',
    display:'flex',
    flexDirection:'column'
  },
  selectedTile:{
    width:'200px',
    height:'300px',
    padding:'20px',
    display:'flex',
    flexDirection:'column',
    border:'2px solid blue'
  },
  price:{
    width:'100%',
    display:'flex',
    alignItems:'center',
    marginTop:'auto'
  },
  checkoutContainer:{
    display:'flex',
    flexDirection:'column',
  },
  form:{
    display:'flex',
    flexDirection:'column',
    padding:'20px',
    marginTop:'20px'
  },
  label:{
    marginTop:'20px'
  },
  cardContainer:{
    width:'240px'
  },
  page:{
    backgroundImage:'linear-gradient(to right,#2677d4,#5c97db)',
    paddingTop:'40px',
    minHeight:'95vh',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    width:'100%'
  },
  logoContainer:{
    backgroundColor:'white',
    width:'200px',
    padding:'10px',
    borderRadius:'5px'
  }
}

export default style;
