import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Clear from '@material-ui/icons/Clear';
import Icon from '@material-ui/core/Icon';

class NotFoundView extends Component{

  render(){
    const outerStyle={
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
    }
    const paperStyle={
      height:'50px',
      width: '600px',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      marginTop:'20px',
      backgroundColor:'tomato'
    }
    const lineStyle={
      marginTop:'20px'
    }
    return(
      <section style={outerStyle}>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:'20px'}}>
          <Icon><Clear style={{color:'red'}}/></Icon>
          <Typography style={{fontSize:'150%',marginLeft:'20px'}}>Verification Failed</Typography>
        </div>
        <Typography style={lineStyle}>Could not locate a document with hash:</Typography>
        <Paper style={paperStyle}>{this.props.fileHash}</Paper>
        <Button style={lineStyle} onClick={this.props.clearState} variant='contained'>Back</Button>
      </section>
    )
  }
}

export default NotFoundView;
