import React, { Component } from 'react';
import {CardElement,injectStripe} from 'react-stripe-elements';
import {Paper,Typography,TextField,Button,InputLabel} from '@material-ui/core';
import style from '../styles/subscriptionStyle';
import states from '../utils/statesUS';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DefaultTopBar from '../subscription/DefaultTopBar';

class CheckOutView extends Component{

  handleSubmit=async()=>{
    const {
      email,
      name,
      street,
      unit,
      city,
      state
    } = this.props.state;
    const cardElement = this.props.elements.getElement('card');
    const result = await this.props.stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: name,
          email: email,
          address:{
            city: city,
            state: state,
            line1: street,
            line2: unit
          }
        },
      });
    if(result.error){
      console.log(result)
      alert(result.error.message);
    }else{
      this.props.handleSubmit(result.paymentMethod.id);
    }
  }

  render(){
    const {
      email,
      password,
      company,
      name,
      street,
      unit,
      city,
      state,
      url
    } = this.props.state;
    const enabled = email && password.length > 6 && company && street && city && state;
    return(
      <div>
      <DefaultTopBar/>
      <div style={style.container}>
        <div style={style.checkoutContainer}>
        <Typography variant='h5'>Checkout</Typography>
        <Typography variant='h6' style={style.label}>*Try FREE for 30 days.</Typography>
        <Typography>Cancel anytime before trial ends and your card will not be billed.</Typography>
        <Typography variant='h6' style={style.label}>{this.props.state.selection}</Typography>
        <Typography variant='h6' style={style.label}>Account Details</Typography>
        <Paper style={style.form}>
          <TextField id='email' label='Email' type='text'
            value={email} onChange={this.props.handleChange('email')}/>
          <TextField id='password' label='Password' type='password'
            value={password} onChange={this.props.handleChange('password')}/>
          <TextField id='company' label='Company' type='text'
            value={company} onChange={this.props.handleChange('company')}/>
          <TextField id='url' type='text'
            value={url} onChange={this.props.handleChange('url')}
            label='SigningAgent.com URL'
            />
          <Typography>*Required only for Certified Signing Agents</Typography>
        </Paper>
        <Typography variant='h6' style={style.label}>Billing Details</Typography>
        <Paper style={style.form}>
          <TextField id='name' label='Cardholder Name' type='text'
            value={name} onChange={this.props.handleChange('name')}/>
          <TextField id='street' label='Address Line 1' type='text'
            value={street} onChange={this.props.handleChange('street')}/>
          <TextField id='unit' label='Address Line 2' type='text'
            value={unit} onChange={this.props.handleChange('unit')}/>
          <TextField id='city' label='City' type='text'
            value={city} onChange={this.props.handleChange('city')}/>
          <InputLabel>State</InputLabel>
          <Select value={state} onChange={this.props.handleChange('state')}>
            {states.map(state=>{
              return <MenuItem key={state.abbreviation} value={state.abbreviation}>{state.name}</MenuItem>
            })}
          </Select>
        </Paper>
        <Paper style={{padding:'20px',width:'300px',margin:'20px 0px 20px'}}>
          <CardElement/>
        </Paper>
        <Button
        variant='contained'
        color='primary'
        onClick={this.handleSubmit}
        disabled={!enabled}
        >
        Submit
        </Button>
        {
          this.props.error &&
          <Typography style={{color:'red',marginTop:'20px'}}>{this.props.error}</Typography>
        }
        </div>
      </div>
      </div>
    )
  }
}

export default injectStripe(CheckOutView);
