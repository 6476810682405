import React from 'react';
import {
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import style from '../styles/subscriptionStyle';
import {Link} from 'react-router-dom';
import agentIcon from '../images/icon1.png';

const PlanCard=(props)=>{

  return(
    <Link to={'/checkout/'+props.plan} style={{textDecoration:'none'}}>
      <Card style={style.cardContainer}>
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',backgroundColor:props.headerColor,paddingTop:'10px'}}>
          <Typography variant='h6' style={{color: props.plan === 'free' ? 'black' : 'white'}}>{props.title}</Typography>
          <div style={{backgroundColor:'white',borderRadius:'50%',padding:'15px',display:'flex',alignItems:'center',justifyContent:'center',marginBottom:'-5px',zIndex:1}}>
            <img src={props.icon} alt={agentIcon} style={{height:'30px',width:'auto'}}/>
          </div>
          <div style={{backgroundColor:'#112b4a',width:'100%',display:'flex',justifyContent:'center',padding:'10px'}}>
            <Typography style={{color:'white',fontWeight:'bold'}}>{props.subTitle}</Typography>
          </div>
        </div>
        <CardContent>
          {props.content}
        </CardContent>
      </Card>
    </Link>
  )
}

export default PlanCard;
