const style={
  accountFrame:{
    padding:'40px 80px'
  },
  accountLabel:{
    marginTop:'20px'
  },
  accountHeader:{
    display:'flex',
    alignItems:'center',
    width:'100%'
  },
  logOut:{
    marginTop:'20px'
  }
}

export default style;
