import{
  SET_USER,
  SET_TOKEN,
  SET_APP_LOADING,
  SET_PROFILE
} from '../action_types/appActionTypes';

export const setUser = (user) =>{
  return{
    type: SET_USER,
    user
  }
}

export const setToken = (token) =>{
  return{
    type: SET_TOKEN,
    token
  }
}

export const setAppLoading = (appLoading) =>{
  return{
    type: SET_APP_LOADING,
    appLoading
  }
}

export const setProfile = (profile) =>{
  return{
    type: SET_PROFILE,
    profile
  }
}
