import{
  SET_USER,
  SET_TOKEN,
  SET_APP_LOADING,
  SET_PROFILE
} from '../action_types/appActionTypes';

const initialState ={
  user: null,
  token: null,
  appLoading: true,
  profile: null
}

const appReducer = (state = initialState, action) =>{
  switch(action.type){
    case SET_USER:
      return{
        ...state,
        user: action.user
      }
    case SET_TOKEN:
      return{
        ...state,
        token: action.token
      }
    case SET_APP_LOADING:
      return{
        ...state,
        appLoading: action.appLoading
      }
    case SET_PROFILE:
      return{
        ...state,
        profile: action.profile
      }
    default:
      return state
  }
}

export default appReducer;
