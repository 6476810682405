import React, {Component} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  TextField
} from '@material-ui/core';

class ShareDialog extends Component{
  state={
    shared: this.props.shared ? JSON.parse(JSON.stringify(this.props.shared)) : [],
    email: ''
  }

  handleRemove=(email)=>{
    let shared = this.state.shared;
    const index = shared.findIndex(item=>item === email);
    if(index !== -1){
      shared.splice(index,1);
      this.setState({shared : shared});
    }
  }

  handleClose=()=>{
    this.setState({shared : this.props.shared});
    this.props.closeShare();
  }

  handleEmail=(value)=>{
    const lower = value.toLowerCase();
    this.setState({email : lower});
  }

  handleAdd=()=>{
    let shared = this.state.shared;
    shared.push(this.state.email.replace(/ /g, ''));
    this.setState({email: '', shared: shared});
  }

  render(){
    return(
      <Dialog
      open={this.props.shareOpen}
      onClose={this.handleClose}
      >
        <DialogTitle>Sharing</DialogTitle>
        <DialogContent>
          <List style={{width:'100%',height:'200px',overflowY:'scroll'}}>
          {this.state.shared.map(email=>{
            return(
              <ListItem key={email}>
                <ListItemText primary={email}/>
                <ListItemSecondaryAction>
                  <Button
                  style={{color:'red'}}
                  onClick={()=>{this.handleRemove(email)}}
                  >
                  Remove
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
          </List>
          <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            <TextField
            id='email'
            type='email'
            value={this.state.email}
            onChange={ev=>{this.handleEmail(ev.target.value)}}
            variant='outlined'
            style={{width:'100%'}}
            />
            <Button
            onClick={this.handleAdd}
            >
            add
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
          onClick={this.handleClose}
          >
          Cancel
          </Button>
          <Button
          variant='contained'
          color='primary'
          onClick={()=>{this.props.handleSave(this.state.shared)}}
          >
          Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ShareDialog;
