import React, { Component } from 'react';
import UploadView from './UploadView';
import ConfirmView from './ConfirmView';
import BlockchainView from './BlockchainView';
import StatusView from './StatusView';
import EmailView from './EmailView';
import {createEmail} from '../email/email';
import {uploadDocument,createDocument,initDocuments} from '../document/document';
import {connect} from 'react-redux';
import {Redirect,withRouter} from 'react-router-dom';
import {incrementTx} from '../subscription/subscription';
import MetaMaskDialog from '../notification/MetaMaskDialog';
import SingleView from './SingleView';
import shajs from 'sha.js';

import RemotaryABI from '../contract/RemotaryABI';
//dev
const devAddress = process.env.REACT_APP_DEV_ADDRESS;
const address = process.env.REACT_APP_CONTRACT_ADDRESS;
const prodAddress = process.env.REACT_APP_PROD_ADDRESS;
const gasPrice = process.env.REACT_APP_GAS_PRICE;
const gasLimit = process.env.REACT_APP_GAS_LIMIT;

class UploadContainer extends Component{
  state={accepted:[],
    rejected:[],
    confirmed:false,
    email: this.props.user ? this.props.user.email : '',
    fileHash:'',
    publicKey:'',
    status:'Pending',
    submitted:false,
    txHash:'',
    buffer:'',
    ipfsHash:'',
    recipients:[],
    dialog:false,
    blockchainSelected: false,
    uploading:false,
    infoOpen:null,
    emailInput:'',
  }

  setPublicKey=async(props)=>{
    if(props.web3){
      var accounts = await props.web3.eth.getAccounts();
      if(accounts.length>0){
        this.setState({publicKey:accounts[0]});
      }
    }
  }

  componentDidMount=()=>{
    this.setPublicKey(this.props);
    if(!window.ethereum){
      this.setState({dialog:true});
    }
  }

  componentWillReceiveProps=(props)=>{
    this.setPublicKey(props);
  }

  componentDidUpdate=(prevProps)=>{
    if(this.props.user && !prevProps.user){
      this.setState({email: this.props.user.email});
    }
  }

  fileToString = (file)=> {
    return new Promise((resolve, reject)=>{
      const fileReader = new FileReader();
      fileReader.onerror = (error)=>{
        fileReader.abort();
        reject(error);
      }
      fileReader.onloadend=()=>{
        resolve(fileReader.result);
      }
      fileReader.readAsDataURL(file);
    });
  }

  fileToBuffer =(file)=>{
    return new Promise((resolve, reject)=>{
      const fileReader = new FileReader();
      fileReader.onerror = (error)=>{
        fileReader.abort();
        reject(error);
      }
      fileReader.onloadend=()=>{
        resolve(fileReader.result);
      }
      fileReader.readAsArrayBuffer(file);
    });
  }

  onDropAccepted =async(accepted)=>{
    // this.setState({accepted:this.state.accepted.concat(accepted),rejected:[]});
    //new code
    try{
      var arrayBuffer = await this.fileToBuffer(accepted[0]);
      const buffer = await Buffer.from(arrayBuffer);
      this.setState({buffer:buffer,accepted:[accepted[0]]});
    }catch(error){
      console.log(error);
    }
  }

  onDropRejected = (rejected)=>{
    this.setState({rejected:rejected})
  }

  clearAccepted =()=>{
    this.setState({accepted:[],rejected:[]})
  }

  confirm = async()=>{
    this.setState({confirmed:true})
    if(this.state.accepted.length>0){
      try{
        var arrayBuffer = await this.fileToBuffer(this.state.accepted[0]);
        const buffer = await Buffer.from(arrayBuffer);
        this.setState({buffer:buffer});
      }catch(error){
        console.log(error);
      }
    }
  }

  unconfirm=()=>{
    this.setState({confirmed:false,email:'',fileHash:''})
  }

  setEmail=(email)=>{
    this.setState({email:email});
  }

  clearEmail=()=>{
    this.setState({email:''});
  }

  clearSubmit=()=>{
    this.setState({submitted:false,status:'Pending'})
  }

  setRecipients=(recipients)=>{
    this.setState({recipients:recipients});
  }

  deployEth=async()=>{
    const remotary = new this.props.web3.eth.Contract(RemotaryABI);
    remotary.options.address = this.props.network === 'rinkeby' ? devAddress : address;
    const accounts = await this.props.web3.eth.getAccounts();
    remotary.methods.upload(this.state.email,this.state.ipfsHash).send(
      {from:accounts[0],gas:gasLimit,gasPrice:gasPrice}
    )
    .on('transactionHash',function(hash){
      console.log(hash);
      this.setState({status:'Processing'})
    }.bind(this))
    .on('receipt',function(receipt){
      console.log(receipt);
      this.setState({status:'Complete',txHash:receipt.transactionHash});
    }.bind(this))
    .on('error',function(error){
      console.log(error);
      this.setState({status:'Error'})
    }.bind(this));
  }

  sendReceipts=async(link)=>{
    // const recipients = this.state.recipients.split(',');
    this.state.recipients.forEach(recipient=>{
      createEmail(recipient,link);
    })
  }

  deploy=async(firebase)=>{
    const hash = shajs('sha256').update(this.state.buffer).digest('hex');
    if(hash){
      this.setState({ipfsHash:hash})
    }
    const storageRef = firebase.storage().ref();
    const fileName = String(Date.now()+'-'+this.state.accepted[0].name);
    const fileRef = storageRef.child(String(fileName));
    const result = await fileRef.put(this.state.accepted[0]);
    if(result.state === 'success'){
      const downloadUrl = await fileRef.getDownloadURL();
      const result = await createDocument(fileName,downloadUrl,this.state.recipients.join(),hash,this.state.publicKey,this.props.token);
      // this.props.uploadDocument(this.state.accepted[0],this.props.token,this.state.recipients,hash,this.state.publicKey);
      incrementTx(this.props.token);
      this.sendReceipts(prodAddress);
      if(this.state.blockchainSelected){
        this.deployEth();
      }
      this.props.initDocuments(this.props.token);
    }else{
      alert('File upload failed')
    }
    this.setState({submitted:true});
  }

  handleBlockchainSelect=(value)=>{
    this.setState({
      blockchainSelected: value
    });
  }

  handleUpload=()=>{
    this.setState({uploading:true});
    this.deploy(this.props.firebase);
  }

  handleInfoOpen=(value)=>{
    console.log(value)
    this.setState({infoOpen:value});
  }

  clearAccepted=()=>{
    this.setState({accepted:[]})
  }

  handleEmailInput=(value)=>{
    const lower = value.toLowerCase();
    this.setState({emailInput:lower})
  }

  render(){
    const acceptedTypes = 'application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain, image/jpeg, image/png, application/msword';

    if(!this.props.token){
      return <Redirect exact to='/login'/>
    }

    if(this.state.submitted){
      return(
        <StatusView status={this.state.status} clearSubmit={this.clearSubmit} txHash={this.state.txHash}
              ipfsHash={this.state.ipfsHash} network={this.props.network}
              blockchainSelected={this.state.blockchainSelected}
              />
      );
    }

    return(
      <SingleView
      accepted={this.state.accepted}
      rejected={this.state.rejected}
      onDropAccepted={this.onDropAccepted}
      onDropRejected={this.onDropRejected}
      acceptedTypes={acceptedTypes}
      recipients={this.state.recipients}
      setRecipients={this.setRecipients}
      handleBlockchainSelect={this.handleBlockchainSelect}
      blockchainSelected={this.state.blockchainSelected}
      network={this.props.network}
      uploading={this.state.uploading}
      handleUpload={this.handleUpload}
      infoOpen={this.state.infoOpen}
      handleInfoOpen={this.handleInfoOpen}
      clearAccepted={this.clearAccepted}
      emailInput={this.state.emailInput}
      handleEmailInput={this.handleEmailInput}
      />
    )

    // return(
    //   <div>
    //     {this.state.submitted ?
    //       <StatusView status={this.state.status} clearSubmit={this.clearSubmit} txHash={this.state.txHash}
    //       ipfsHash={this.state.ipfsHash}/>
    //     :
    //       this.state.recipients ?
    //       <BlockchainView setRecipients={this.setRecipients} deploy={this.deploy}
    //         fileName={this.state.accepted[0].name} lastModified={this.state.accepted[0].lastModified}
    //         email={this.state.email} publicKey={this.state.publicKey} network={this.props.network}
    //         handleBlockchainSelect={this.handleBlockchainSelect} blockchainSelected={this.state.blockchainSelected}
    //         />
    //     :
    //       this.state.confirmed ?
    //       <EmailView setRecipients={this.setRecipients} unconfirm={this.unconfirm}/>
    //     :
    //       this.state.accepted.length >0 ?
    //       <ConfirmView file={this.state.accepted[0]} clearAccepted={this.clearAccepted}
    //         confirm={this.confirm}/>
    //     :
    //     <UploadView accepted={this.state.accepted} rejected={this.state.rejected}
    //     onDropAccepted={this.onDropAccepted} onDropRejected={this.onDropRejected}
    //     acceptedTypes={acceptedTypes}/>
    //     }
    //     <MetaMaskDialog open={this.state.dialog} handleClose={()=>{}}/>
    //   </div>
    // )
  }
}

const mapStateToProps=(state)=>{
  return{
    token:state.app.token,
    user: state.app.user,
    profile: state.app.profile
  }
}

const mapDispatchToProps={
  uploadDocument,
  initDocuments
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(UploadContainer));
