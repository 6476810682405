import React from 'react';
import{
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@material-ui/core';
import metamask from '../images/metamask.png';

const MetaMaskDialog =(props)=>{

  return(
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>MetaMask Extension Not Detected</DialogTitle>
      <DialogContent>
        <div style={{width:'100%',display:'flex',justifyContent:'center', marginBottom:'20px'}}>
          <img src={metamask} alt={metamask} style={{height:'40px',width:'auto'}}/>
        </div>
        <DialogContentText>
        MetaMask Chrome Extension is required to Upload and Verify. Click below to install.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <a href="https://metamask.io/">
          <Button color='primary'>Install</Button>
        </a>
      </DialogActions>
    </Dialog>
  )
}

export default MetaMaskDialog;
