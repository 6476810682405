import React, { Component } from 'react';
import{
  Typography,
  Button,
  IconButton,
  AppBar,
  Toolbar
} from '@material-ui/core';
import {Link,withRouter} from 'react-router-dom';
import logo from '../images/Logo.png';
import {connect} from 'react-redux';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import BackupIcon from '@material-ui/icons/Backup';

class TopBar extends Component{

  render(){
    const {profile,monthlyTx} = this.props;
    const txLimit = profile ? profile.txLimit ? profile.txLimit : 0 : 0;
    const uploadEnabled = txLimit > monthlyTx;
    const blue = '#0063a8';

    return(
      <AppBar position='static' style={{background:'transparent'}}>
        <Toolbar style={{padding:'0px'}}>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            <div style={{marginLeft:'10px'}}>
              <Link to='/' style={{textDecoration:'none'}}>
                <img src={logo} alt={logo} style={{height:'40px',width:'auto'}}/>
              </Link>
            </div>
            {this.props.network === 'disconnected' ?
              <a target='_blank' rel="noopener noreferrer" href='https://metamask.io/' style={{textDecoration:'none'}}>
                <Typography style={{marginLeft:'100px',color:blue}}>Get Started: Install MetaMask plugin</Typography>
              </a>
              :
              <Typography style={{marginLeft:'100px',color:blue}}>Network: {this.props.network}</Typography>
            }
          </div>
          <div style={{marginLeft:'auto'}}>
            <Link to='/myAccount' style={{textDecoration:'none'}}>
              <Button >My Account</Button>
            </Link>
            <a href='https://safeboxsigning.com/safebox-instructions/' style={{textDecoration:'none'}}>
              <Button>Instructions</Button>
            </a>
            <Link to='/verify' style={{textDecoration:'none'}}>
              <Button >Verify</Button>
            </Link>
            <IconButton
            onClick={this.props.logOut}
            >
              <PowerSettingsNewIcon style={{color:blue}}/>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    )
  }
}

const mapStateToProps=(state)=>{
  return{
    profile: state.app.profile,
    monthlyTx: state.documents.monthlyTx
  }
}

const mapDispatchToProps={

}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(TopBar));
