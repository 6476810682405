import React from 'react';
import {Typography,CircularProgress} from '@material-ui/core';
import style from '../styles/subscriptionStyle';

const ProcessingView=(props)=>{

  return(
    <div style={style.container}>
      <Typography variant='h5' style={{margin:'40px'}}>
        Processing Secure Payment
      </Typography>
      <CircularProgress/>
    </div>
  )

}

export default ProcessingView;
