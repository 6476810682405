const url = process.env.REACT_APP_EMAIL_URL;

const createEmail = async(to,link)=>{
  try{
    const result = await fetch(url + '/createEmail',{
      method:'POST',
      headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body:JSON.stringify({
        to:to,
        link:link
      })
    })
    console.log(result)
    const jsonResult = await result.json();
    if(!result.ok){
        console.log(jsonResult);
    }
    return jsonResult;
  }catch(err){
    console.log(err);
    return null;
  }
}

export {createEmail};
