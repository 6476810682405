import {
  setMyDocuments,
  setShared,
  setMonthlyTx
} from '../redux/actions/documentActions';

const url = process.env.REACT_APP_EMAIL_URL;

const uploadDocument = (file,token,shared,ipfsHash,publicKey)=>{
  return async(dispatch,getState)=>{
    const formData = new FormData();
    formData.append('file',file);
    formData.append('token',token);
    formData.append('shared',shared);
    formData.append('ipfsHash',ipfsHash);
    formData.append('publicKey',publicKey);
    try{
      const result = await fetch(url + '/uploadDocument',{
        method:'POST',
        body: formData
      })
      const jsonResult = await result.json();
      if(!result.ok){
          console.log(jsonResult);
      }
      fetchMyDocuments(token,dispatch);
      return jsonResult;
    }catch(err){
      console.log(err);
      return null;
    }
  }
}

const createDocument = async(name,downloadUrl,shared,ipfsHash,publicKey,token)=>{
  try{
    const result = await fetch(url + '/createDocument',{
      method:'POST',
      headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':token
      },
      body: JSON.stringify({
        name: name,
        downloadUrl: downloadUrl,
        shared: shared,
        ipfsHash: ipfsHash,
        publicKey: publicKey
      })
    })
    const jsonResult = await result.json();
    if(!result.ok){
        console.log(jsonResult);
    }
    return jsonResult;
  }catch(err){
    console.log(err);
    return null;
  }
}

const checkTransactions = async(documents,dispatch)=>{
  let monthlyCount = 0;
  const month = new Date().getMonth();
  documents.forEach(doc=>{
    const docMonth = new Date(doc.timestamp).getMonth();
    if(docMonth === month){
      monthlyCount++;
    };
  });
  dispatch(setMonthlyTx(monthlyCount));
}

const fetchMyDocuments = async(token,dispatch)=>{
  try{
    const result = await fetch(url + '/myDocuments',{
      headers:{
        'Authorization':token
      }
    });
    if(!result.ok){
      console.log(result);
    }
    let jsonResult = await result.json();
    checkTransactions(jsonResult.data,dispatch);
    dispatch(setMyDocuments(jsonResult.data));
    return jsonResult.data;
  }catch(err){
    console.log(err);
    return null;
  }
}

const fetchSharedWithMe = async(token,dispatch)=>{
  try{
    const result = await fetch(url + '/sharedWithMe',{
      headers:{
        'Authorization':token
      }
    });
    if(!result.ok){
      console.log(result);
    }
    let jsonResult = await result.json();
    dispatch(setShared(jsonResult.data));
    return jsonResult.data;
  }catch(err){
    console.log(err);
    return null;
  }
}

const fetchFile = async(fileName)=>{
  try{
    const result = await fetch(url + '/fetchFile?name='+fileName);
    if(!result.ok){
      console.log(result)
    }
    let jsonResult = await result.json();
    return jsonResult.data;
  }catch(err){
    console.log(err);
  }
}

const updateShared = async(uid,shared)=>{
  try{
    const result = await fetch(url + '/updateShared',{
      method:'POST',
      headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uid: uid,
        shared: shared
      })
    })
    const jsonResult = await result.json();
    if(!result.ok){
        console.log(jsonResult);
    }
    return jsonResult;
  }catch(err){
    console.log(err);
    return null;
  }
}

const initDocuments = (token) =>{
  return async(dispatch,getState)=>{
    fetchMyDocuments(token, dispatch)
    fetchSharedWithMe(token, dispatch);
  }
}

export {uploadDocument, initDocuments, fetchFile, updateShared,createDocument};
