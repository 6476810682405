import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

class VerifyView extends Component{
  state={email:''}

  handleChange = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  validateEmail=(email)=> {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  verify=()=>{
    if(this.props.fileHash){
      if(this.validateEmail(this.state.email)){
        this.props.setEmail(this.state.email)
      }else{
        alert('Please enter a valid email address.')
      }
    }else{
      alert('Please upload a file before continuing.')
    }
  }

  render(){
    const outerStyle={
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
    }

    const dropStyle={
      backgroundColor:'rgba(240,248,255,1)',
      width: '600px',
      height:'200px',
      borderWidth:'2px',
      borderColor:'rgba(102,102,102,1)',
      borderStyle: 'dashed',
      borderRadius: '5px',
      marginTop:'20px',
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      justifyContent:'center'
    }
    const acceptStyle={
      backgroundColor:'rgba(240,248,255,1)',
      width: '600px',
      height:'200px',
      borderWidth:'2px',
      borderColor:'lime',
      borderStyle: 'solid',
      borderRadius: '5px',
      marginTop:'20px',
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      justifyContent:'center'
    }
    const rejectStyle={
      backgroundColor:'rgba(237,200,220,1)',
      width: '600px',
      height:'200px',
      borderWidth:'2px',
      borderColor:'red',
      borderStyle: 'solid',
      borderRadius: '5px',
      marginTop:'20px',
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      justifyContent:'center'
    }
    const directionStyle={
      fontSize:'130%'
    }
    const paperStyle={
      height:'50px',
      width: '400px',
      display:'flex',
      alignItems:'center',
      justifyContent:'center'
    }
    const sectionStyle={
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      marginTop:'20px'
    }
    const dropMessage = this.props.rejected.length > 0 ?
      <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
        <Typography style={directionStyle}>The file could not be uploaded</Typography>
        <Typography style={directionStyle}>Please check that it is a valid type</Typography>
      </div>
      :
      <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
        <Typography style={directionStyle}>Drag and drop a file</Typography>
        <Typography style={directionStyle}>OR</Typography>
        <Typography style={directionStyle}>Click anywhere in this area</Typography>
      </div>
    return(
      <div>
        <section>
          <div style={outerStyle}>
            <Typography style={{marginTop:'20px',fontSize:'150%'}}>Step 1: Upload a document</Typography>
            <Dropzone accept={this.props.acceptedTypes} style={dropStyle} acceptStyle={acceptStyle}
              rejectStyle={rejectStyle} onDropAccepted={this.props.onDropAccepted} onDropRejected={this.props.onDropRejected}>
              {dropMessage}
            </Dropzone>
            <Typography style={{marginTop:'20px'}}>Please upload one file at a time. File types include PDF, TXT, or DOCX</Typography>
          </div>
        </section>
        <section style={sectionStyle}>
          <div>
            <Typography>Document Hash:</Typography>
            <Paper style={paperStyle}>
              <Typography style={{fontSize:'10px'}}>{this.props.fileHash}</Typography>
            </Paper>
          </div>
        </section>
        <section style={sectionStyle}>
            <Typography style={{fontSize:'150%',marginTop:'40px'}}>Step 2: What email address is associated with the document?</Typography>
            <TextField style={{marginTop:'20px',width:'400px'}} variant='outlined' type='email' onChange={this.handleChange} placeholder='email@example.com' value={this.state.email}/>
        </section>
        <section style={sectionStyle}>
          <div style={{marginTop:'40px',width:'400px',display:'flex',justifyContent:'center',marginBottom:'100px'}}>
            <Button onClick={this.verify} style={{width:'150px'}} variant='contained' color='primary'>Verify</Button>
          </div>
        </section>
      </div>
    )
  }
}

export default VerifyView;
