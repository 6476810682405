import React, { Component } from 'react';
import style from '../styles/myAccountStyle';
import BackIcon from '@material-ui/icons/ArrowBack';
import {Link} from 'react-router-dom';

import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  CircularProgress
} from '@material-ui/core';

class MyAccountView extends Component{

  state={
    open:false
  }

  handleOpen=()=>{
    this.setState({open:true});
  }

  handleClose=()=>{
    this.setState({open:false});
  }

  handleConfirm=()=>{
    this.setState({open:false});
    this.props.handleUnsubscribe();
  }

  render(){

    const {profile,loading,logOut} = this.props;

    return(
      <div style={style.accountFrame}>
        <Link to='/dashboard' style={{textDecoration:'none',color:'black'}}>
          <BackIcon/>
        </Link>
        <Typography
        variant='h4'
        style={{marginBottom:'40px',marginTop:'20px'}}
        >My Account</Typography>
        {
          profile &&
          <section>
            <Typography variant='h6' style={{marginBottom:'40px'}}>{profile.email}</Typography>
            <Typography variant='h6' style={{marginBottom:'40px'}}>{profile.txLimit} Transactions/Month</Typography>
          </section>
        }
        <section style={style.logOut}>
          <Button
          variant='outlined'
          color='primary'
          onClick={logOut}
          >
          Log Out
          </Button>
        </section>
        <section>
        {
          loading ?
          <CircularProgress/>
          :
          (profile && profile.txLimit > 0) ?
          <Button
          style={{color:'red', marginTop:'60px'}}
          variant='outlined'
          onClick={this.handleOpen}
          >
          Cancel Subscription
          </Button>
          :
          <Typography>No Plan Selected</Typography>
        }
        </section>
        <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        >
          <DialogTitle>Are you sure you want to cancel?</DialogTitle>
          <DialogActions>
            <Button
            onClick={this.handleClose}
            >
            Go Back
            </Button>
            <Button
            variant='contained'
            color='primary'
            onClick={this.handleConfirm}
            >
            Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default MyAccountView;
