import React from 'react';
import style from '../styles/dashboardStyle';
import{
  Typography,
  Grid,
  Card,
  Button
} from '@material-ui/core';
import {Link} from 'react-router-dom';
import paper from '../images/paper.png';
import share from '../images/share.png';
import borrow from '../images/borrow.png';
import BackupIcon from '@material-ui/icons/Backup';

const DashboardView =(props)=>{
  const {profile, monthlyTx, network} = props;
  const txLimit = profile ? profile.txLimit ? profile.txLimit : 0 : 0;
  const uploadEnabled = txLimit > monthlyTx;
  const disconnected = network === 'disconnected';

  return(
    <div>
    {
      disconnected &&
      <section style={{width:'100%',backgroundColor:'yellow',padding:'10px 0px',display:'flex',justifyContent:'center'}}>
        <Typography>
        You must install the  <a target='_blank' rel="noopener noreferrer" href='https://metamask.io/'>Metamask plugin</a> to use SAFEBOX
        </Typography>
      </section>
    }
    <div style={style.dashboardFrame}>
      <section style={{width:'100%',display:'flex',justifyContent:'center'}}>
        <Typography variant='h5' style={{margin:'40px'}}>Explore Through Your Safebox</Typography>
      </section>
      <Grid container spacing={40} justify='center'>
        <Grid item sm={12} md={4}>
          <Link to='/myDocuments' style={{textDecoration:'none'}}>
            <Card style={style.card}>
              <img src={paper} alt={paper} style={style.icon}/>
              <Typography variant='h5' style={style.cardText}>My Documents</Typography>
              <Typography variant='h3' style={style.number}>{props.myDocuments.length}</Typography>
            </Card>
          </Link>
        </Grid>
        <Grid item sm={12} md={4}>
          <Link to='/shared' style={{textDecoration:'none'}}>
            <Card style={style.card}>
              <img src={share} alt={share} style={style.icon}/>
              <Typography variant='h5' style={style.cardText}>Shared With Me</Typography>
              <Typography variant='h3' style={style.number}>{props.shared.length}</Typography>
            </Card>
          </Link>
        </Grid>
        <Grid item sm={12} md={4}>
          <Card style={style.card}>
            <img src={borrow} alt={borrow} style={style.icon}/>
            <Typography variant='h5' style={style.cardText}>Monthly Transactions</Typography>
            <Typography variant='h3' style={style.number}>{monthlyTx} / {txLimit}</Typography>
          </Card>
        </Grid>
      </Grid>
      <section style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'40px'}}>
      {
        uploadEnabled &&
        <Link to='/upload' style={{textDecoration:'none'}}>
          <Button
          variant='contained'
          color='primary'
          size='large'
          style={{height:'60px',width:'300px'}}
          >
          <BackupIcon style={{marginRight:'5px'}}/>
          Upload a Document
          </Button>
        </Link>
      }
      </section>
      <section style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'40px'}}>
        <Typography style={{color:'#0063a8'}}>Feel free to contact us at: support@safeboxsigning.com</Typography>
      </section>
    </div>
    </div>
  )
}

export default DashboardView;
