import React, { Component } from 'react';
import {Redirect,withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import DashboardView from './DashboardView';

class DashboardContainer extends Component{

  render(){
    if(!this.props.token){
      return <Redirect exact to='/login'/>
    }

    return(
      <div>
        <DashboardView {...this.props}/>
      </div>
    )
  }
}

const mapStateToProps=(state)=>{
  return{
    token:state.app.token,
    myDocuments: state.documents.myDocuments,
    shared: state.documents.shared,
    profile: state.app.profile,
    monthlyTx: state.documents.monthlyTx
  }
}

const mapDispatchToProps={

}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(DashboardContainer));
