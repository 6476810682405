import React, { Component } from 'react';
import{
  Typography,
  Paper,
  TextField,
  Button
} from '@material-ui/core';
import DefaultTopBar from './DefaultTopBar';
import style from '../styles/subscriptionStyle';

class FreeView extends Component{

  render(){
    const {
      email,
      password,
      company,
      name,
    } = this.props.state;
    const enabled = email && password.length > 6;
    return(
      <div>
        <DefaultTopBar/>
        <div style={style.container}>
          <div style={style.checkoutContainer}>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'40px'}}>
            <Typography variant='h5'>Try SAFEBOX Free for 30 days</Typography>
            <Typography style={{marginTop:'20px'}}>No obligation, no credit card required</Typography>
            </div>
            <Paper style={style.form}>
              <TextField id='email' label='Email' type='text'
                value={email} onChange={this.props.handleChange('email')}/>
              <TextField id='password' label='Password' type='password'
                value={password} onChange={this.props.handleChange('password')}/>
              <TextField id='name' label='First and Last Name' type='text'
                value={name} onChange={this.props.handleChange('name')}/>
              <TextField id='company' label='Company' type='text'
                value={company} onChange={this.props.handleChange('company')}/>
            </Paper>
            <Button
            variant='contained'
            color='primary'
            onClick={this.props.handleSubmit}
            disabled={!enabled}
            style={{marginTop:'40px'}}
            >
            Get Started
            </Button>
            {
              this.props.error &&
              <Typography style={{color:'red',marginTop:'20px'}}>{this.props.error}</Typography>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default FreeView;
