import React from 'react';
import style from '../styles/logInStyle';
import {
  Button,
  TextField,
  Typography,
  Hidden
} from '@material-ui/core';
import {Link} from 'react-router-dom';
import graphic from '../images/LoginGraphic.png';
import logo from '../images/Logo.png';
import MediaQuery from 'react-responsive';

const LogInView=(props)=>{
  const {email,password} = props;
  const enabled = email.length >0 && password.length >0;

  return(
    <div style={style.page}>
      <Hidden xsDown>
        <div style={style.logoContainer}>
          <img src={logo} alt={logo} style={{height:'40px',width:'auto'}}/>
        </div>
      </Hidden>
      <div style={style.container}>
        <MediaQuery query='(min-width:900px)'>
          <div>
            <Typography variant='h5' style={{margin:'40px 0px 20px',color:'white'}}>Tap Into The Secure World of Blockchain</Typography>
            <Typography style={{marginBottom:'20px',color:'white'}}>Make your documents immutable in minutes.</Typography>
            <img src={graphic} alt={graphic} style={{height:'300px', width:'auto'}}/>
          </div>
        </MediaQuery>
        <div style={style.form}>
          <Typography variant='h6' style={style.header}>Login To Your Account</Typography>
          <TextField id='email' label='Email Address'
           type='email' value={props.email} onChange={props.handleChange('email')}
           margin='dense' onKeyPress={props.handleKeyPress}/>
          <TextField id='password' label='Password' type='password'
            value={props.password} onChange={props.handleChange('password')}
             margin='dense' onKeyPress={props.handleKeyPress}/>
          <Button
          disabled={!enabled}
          onClick={props.handleLogIn}
          variant='contained'
          color='secondary'
          style={{marginTop:'40px',borderRadius:'20px',width:'200px'}}
          >
          Log In
          </Button>
          <Typography variant='h6' style={{margin:'60px 0px 20px'}}>New to SAFEBOX? Create Account</Typography>
          <Link to='/checkout/trial' style={{textDecoration:'none'}}>
            <Button
            variant='outlined'
            color='primary'
            style={{borderRadius:'20px',width:'200px'}}
            >
            Sign Up
            </Button>
          </Link>
          <Typography style={{marginTop:'10px',color:'grey'}}>Contact us at: support@safeboxsigning.com</Typography>
          <Link to='/reset'>
            <Typography>Forgot password?</Typography>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LogInView;
