import React, {Component} from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { withStyles } from '@material-ui/core/styles';

const CustomCell = withStyles(theme=>({
  head:{
    padding:'10px',
    position:'sticky',
    top:0,
    backgroundColor:'#FFFFFF'
  },
}))(TableCell);

class CustomTableHead extends Component{
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render(){
    const {order,orderBy} = this.props;
    return(
      <TableHead>
        <TableRow>
          {this.props.columns.map(
            column=>(
              <CustomCell
              align='left'
              key={column.id}
              sortDirection={orderBy === column.id ? order : false}
              >
                <TableSortLabel
                active={orderBy === column.id}
                direction={order}
                onClick={this.createSortHandler(column.id)}
                >
                {column.label}
                </TableSortLabel>
              </CustomCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    )
  }

}

export default CustomTableHead;
