import React, { Component } from 'react';
import {Route,Redirect,Switch,withRouter} from 'react-router-dom';
import UploadContainer from './upload/UploadContainer';
import VerifyContainer from './verify/VerifyContainer';
import Web3 from 'web3';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import yellow from '@material-ui/core/colors/yellow';
import TopBar from './nav/TopBar';
import Instructions from './Instructions';
// import IPFS from 'ipfs-http-client';
import firebase from 'firebase';
import config from './firebaseConfig'
import {connect} from 'react-redux';
import LogInContainer from './user/LogInContainer';
import DashboardContainer from './dashboard/DashboardContainer';
import MyDocumentsContainer from './document/MyDocumentsContainer';
import SignUpView from './subscription/SignUpView';
import CheckOutContainer from './subscription/CheckOutContainer';
import SharedContainer from './shared/SharedContainer';
import {initDocuments} from './document/document';
import {initUser} from './user/user';
import ConfirmationView from './subscription/ConfirmationView';
import MyAccountContainer from './user/MyAccountContainer';
import AllDocumentsContainer from './shared/AllDocumentsContainer';
import ResetView from './user/ResetView';
import{
  Hidden
} from '@material-ui/core';
import MobileBar from './nav/MobileBar';

import{
  setUser,
  setToken,
  setAppLoading
} from './redux/actions/appActions';

firebase.initializeApp(config);

// const ipfs = new IPFS({ host: 'ipfs.infura.io', port: 5001, protocol: 'https' });
// const ipfs = new IPFS({ host: url, port: 5001, protocol: 'http' });

const theme = createMuiTheme({
  palette:{
    primary: blue,
    secondary: yellow,
  }
});

class App extends Component {
  state={web3:null,network:'disconnected'};

  init = async() =>{
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      async(user) =>{
        if(user){
          user.getIdToken().then(async(token)=>{
            this.props.setUser(user);
            this.props.setToken(token);
            this.props.initDocuments(token);
            this.props.initUser(token);
          })
        }
      }
    );
  }

  logOut = async()=>{
    await firebase.auth().signOut();
    this.props.setUser(null);
    this.props.setToken(null);
  }

  componentDidMount=async()=>{
    this.init();
    if(window.ethereum){
      window.web3 = new Web3(window.ethereum);
      try {
          await window.ethereum.enable();
          const network = await window.web3.eth.net.getNetworkType();
          this.setState({web3:window.web3,network:network})
      } catch (error) {
          // User denied account access...
      }
    }
  }

  render() {
    return (
        <MuiThemeProvider theme={theme}>
          {
            this.props.token &&
            <div>
              <Hidden smDown>
                <TopBar network={this.state.network} logOut={this.logOut}/>
              </Hidden>
              <Hidden smUp>
                <MobileBar logOut={this.logOut}/>
              </Hidden>
            </div>
          }
          <Switch>
            <Redirect exact from='/' to='login'/>
            <Route exact path='/login' render={props=>(<LogInContainer auth={firebase.auth()}/>)}/>
            <Route exact path='/signup' render={props=>(<SignUpView auth={firebase.auth()}/>)}/>
            <Route exact path='/checkout/:id' render={props=>(<CheckOutContainer auth={firebase.auth()} {...props}/>)}/>
            <Route exact path='/dashboard' render={props=>
              (<UploadContainer web3={this.state.web3} network={this.state.network} firebase={firebase} {...props}/>)
            }/>
            <Route exact path='/mydocuments' render={props=>
              (<MyDocumentsContainer/>)
            }/>
            <Route exact path='/shared' render={props=>
              (<SharedContainer/>)
            }/>
            <Route exact path='/all' render={props=>
              (<AllDocumentsContainer/>)
            }/>
            <Route exact path='/upload' render={props=>
              (<UploadContainer web3={this.state.web3}  network={this.state.network} firebase={firebase} {...props}/>)
            }/>
            <Route exact path='/verify' render={props=>
              (<VerifyContainer web3={this.state.web3} network={this.state.network} {...props}/>)}/>
            <Route exact path='/instructions' render={props=>
              (<Instructions {...props}/>)}/>
            <Route exact path='/confirmation/:id' render={props=>(<ConfirmationView {...props}/>)}/>
            <Route exact path='/myAccount' render={props=>
              (<MyAccountContainer logOut={this.logOut}/>)
            }/>
            <Route exact path='/reset' render={props=>
              (<ResetView  auth={firebase.auth()}/>)
            }/>
          </Switch>
        </MuiThemeProvider>
    );
  }
}

const mapStateToProps=(state)=>{
  return{
    appLoading:state.app.appLoading,
    token:state.app.token
  }
}

const mapDispatchToProps={
  setUser,
  setToken,
  setAppLoading,
  initDocuments,
  initUser
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(App));
