import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import metaMaskAccount from './images/MetaMaskAccount.png';
import Divider from '@material-ui/core/Divider';
import BlockchainData from './images/BlockchainData.png';
import MetaMaskConfirm from './images/MetaMaskConfirm.png';
import Verified from './images/Verified.png';
import FailureToFetch from './images/FailureToFetch.png';
import EmailFailure from './images/EmailFailure.png';

class Instructions extends Component{

  render(){
    const outerStyle={
      display:'flex',
      flexDirection:'column',
      marginLeft:'10%',
      marginRight:'10%',
      marginTop:'50px'
    }

    const labelStyle={
      fontSize:'120%',
      marginBottom:'10px'
    }
    const infoStyle={
      marginBottom:'10px'
    }
    const sectionStyle={
      marginBottom:'20px'
    }
    const headerStyle={
      fontSize:'180%',
      marginBottom:'20px',
      marginTop:'20px'
    }
    return(
      <div style={outerStyle}>
        <Typography style={headerStyle}>Setup</Typography>
        <section style={sectionStyle}>
          <Typography style={labelStyle}>Download and install Chrome</Typography>
          <Typography style={infoStyle}>The web app requires Google Chrome.
          You can download it <a target='_blank' rel="noopener noreferrer" href='https://www.google.com/chrome/'>here</a></Typography>
        </section>
        <section style={sectionStyle}>
          <Typography style={labelStyle}>Install MetaMask Extension</Typography>
          <Typography style={infoStyle}>MetaMask is a chrome extension that allows you
          to interact with the Ethereum blockchain. You can find it <a target='_blank' rel="noopener noreferrer" href='https://metamask.io/'>here</a></Typography>
          <Typography style={infoStyle}>Once you have MetaMask installed it will ask you to create
          a new account. Store your 12-word seed phrase in a secure location, it is the only way to recover
          your account. </Typography>
        </section>
        <section style={sectionStyle}>
          <Typography style={labelStyle}>Obtain Ether</Typography>
          <Typography style={infoStyle}>Uploading a document to the blockchain requires spending a small amount of ETH. Ether can
          be purchased at <a target='_blank' rel="noopener noreferrer" href='https://www.coinbase.com/'>Coinbase</a> using a bank
          account or credit card. </Typography>
        </section>
        <section style={sectionStyle}>
          <Typography style={labelStyle}>Fund Account</Typography>
          <Typography style={infoStyle}>Once you have purchased some ETH, send it to your MetaMask account. Open
          MetaMask and copy the address listed under Account 1. Send ETH to this address from your Coinbase
          account.</Typography>
          <img src={metaMaskAccount} alt={metaMaskAccount}/>
        </section>
        <Divider/>
        <Typography style={headerStyle}>Upload</Typography>
        <section style={sectionStyle}>
          <Typography style={labelStyle}>Step 1: Selecting a document</Typography>
          <Typography style={infoStyle}>Select a document from your computer using the drag-and-drop menu, or by clicking
          anywhere in the upload area.</Typography>
          <Typography style={infoStyle}>Press "Continue"</Typography>
        </section>
        <section style={sectionStyle}>
          <Typography style={labelStyle}>Step 2: Confirm</Typography>
          <Typography style={infoStyle}>Confirm that all of the document details are correct using the preview panel.</Typography>
          <Typography style={infoStyle}>Press "Continue"</Typography>
        </section>
        <section style={sectionStyle}>
          <Typography style={labelStyle}>Step 3: Contact Info</Typography>
          <Typography style={infoStyle}>Enter the email address you would like to associate with the document. This is how
          the system will verify who uploaded it.</Typography>
          <Typography style={infoStyle}>Press "Continue"</Typography>
        </section>
        <section style={sectionStyle}>
          <Typography style={labelStyle}>Step 4: Upload Data to Blockchain</Typography>
          <Typography style={infoStyle}>This page displays a summary of the data that will be uploaded to the
          Ethereum blockchain. It includes your email address, a hash (or digest) of the original file, your public key
          and an approximate timestamp.</Typography>
          <Typography style={infoStyle}>The original document itself is not uploaded, rather the SHA-256 hash, which is a condensed version.
          If any aspect of the document is altered the hash will change, which ensures integrity of the data.</Typography>
          <img src={BlockchainData} alt={''}/>
          <Typography style={infoStyle}>Your public key is imported from the MetaMask extension. This is what the Ethereum
          network uses to verify your identity. Please ensure MetaMask is unlocked.</Typography>
          <Typography style={infoStyle}>Press "Upload"</Typography>
          <Typography style={infoStyle}>You will see a MetaMask popup confirming the details of your transaction, and the
          approximate cost to upload your data.</Typography>
          <img src={MetaMaskConfirm} alt={''}/>
          <Typography style={infoStyle}>Press "Confirm" to send your transaction to the blockchain.</Typography>
          <Typography style={infoStyle}>You will see an "Upload Status: Pending" dialog. Depending on the state of
          the network, it can take anywhere from 1 to 30 minutes for your transaction to be confirmed.</Typography>
        </section>
        <Divider/>
        <Typography style={headerStyle}>Verify</Typography>
        <section style={sectionStyle}>
          <Typography style={infoStyle}>You need two pieces of information to verify that a document was uploaded
          to the blockchain. The original document, and the uploader email address.</Typography>
          <Typography style={infoStyle}>Select a document from your computer using the drag and drop menu, or
          by clicking anywhere in the upload area.</Typography>
          <Typography style={infoStyle}>Enter in the uploader email address.</Typography>
          <Typography style={infoStyle}>Press "Verify".</Typography>
        </section>
        <section style={sectionStyle}>
          <Typography style={labelStyle}>Verification Success</Typography>
          <Typography style={infoStyle}>A successful verificaton will result in a dialog as below.</Typography>
          <img src={Verified} alt={''}/>
          <Typography style={labelStyle}>Document Integrity</Typography>
          <Typography style={infoStyle}>The smart contract locates your data based on the hash of the original document. If
          the document was not uploaded, or altered in any way, this will result in a verification failure.</Typography>
          <img src={FailureToFetch} alt={''}/>
          <Typography style={labelStyle}>Uploader Identity</Typography>
          <Typography style={infoStyle}>The web app also checks the uploader email address you provide
          against the one stored in the smart contract. If these do not match, it will result in a verification failure.</Typography>
          <Typography style={infoStyle}>For additional security, you can also check the uploader public key. The rules
          of the smart contract ensure the identity of the public key owner.</Typography>
          <img src={EmailFailure} alt={''}/>
        </section>
      </div>
    )
  }

}

export default Instructions;
