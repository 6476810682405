import React, { Component } from 'react';
import {
  Typography,
  Button,
  Grid
} from '@material-ui/core';
import style from '../styles/subscriptionStyle';
import {Link,Redirect,withRouter} from 'react-router-dom';
import freeIcon from '../images/Free.png';
import basicIcon from '../images/Basic.png';
import premiumIcon from '../images/Premium.png';
import PlanCard from './PlanCard';
import {
  Check,
  Clear
} from '@material-ui/icons';
import logo from '../images/Logo.png';
import {connect} from 'react-redux';

const Bullet=(props)=>{
  return(
    <div style={{display:'flex', alignItems:'center'}}>
      {props.checked ? <Check style={{color:'black'}}/> : <Clear style={{color:'black'}}/>}
      <Typography style={{marginLeft:'10px'}}>
      {props.content}
      </Typography>
    </div>
  )
}

const freeContent= <div>
  <Bullet checked={true} content='View Documents'/>
  <Bullet checked={true} content='Secure Storage'/>
  <Bullet checked={true} content='Immutable Blockchain Record'/>
  <Bullet checked={false} content='Upload Documents'/>
  <Bullet checked={false} content='0 Transactions Per Month'/>
</div>

const basicContent= <div>
  <Bullet checked={true} content='Upload Documents'/>
  <Bullet checked={true} content='View Documents'/>
  <Bullet checked={true} content='Secure Storage'/>
  <Bullet checked={true} content='Immutable Blockchain Record'/>
  <Bullet checked={true} content='15 Transactions Per Month'/>
</div>

const premiumContent= <div>
  <Bullet checked={true} content='Upload Documents'/>
  <Bullet checked={true} content='View Documents'/>
  <Bullet checked={true} content='Secure Storage'/>
  <Bullet checked={true} content='Immutable Blockchain Record'/>
  <Bullet checked={true} content='50 Transactions Per Month'/>
</div>

class SignUpView extends Component{

  render(){

    if(this.props.token){
      return <Redirect exact to='/dashboard'/>
    }

    return(
      <div style={style.page}>
        <div style={style.logoContainer}>
          <img src={logo} alt={logo} style={{height:'40px',width:'auto'}}/>
        </div>
        <div style={style.container}>
          <Typography variant='h5' style={{color:'white'}}>Plan Options</Typography>
          <Typography variant='h6' style={{color:'white',marginTop:'20px'}}>Try any plan FREE for 30 days</Typography>
          <section style={style.selector}>
            <Grid container spacing={40} justify='center'>
              <Grid item sm={12} md={4}>
                <PlanCard
                title={'BASIC'}
                content={basicContent}
                plan='basic'
                headerColor='#52c9fa'
                icon={basicIcon}
                subTitle={'15 Transactions/Mo'}
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <PlanCard
                title={'PREMIUM'}
                content={premiumContent}
                plan='premium'
                headerColor='#2c5d96'
                icon={premiumIcon}
                subTitle={'50 Transactions/mo'}
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <PlanCard
                title='FREE'
                content={freeContent}
                plan='free'
                headerColor='#b5e5f9'
                icon={freeIcon}
                subTitle={'View Only'}
                />
              </Grid>
            </Grid>
          </section>
          <Typography style={{margin:'20px 0px 0px', color:'white'}}>Already have an account? Login Now</Typography>
          <Link to='/login' style={{textDecoration:'none'}}>
            <Button
            style={{marginTop:'20px',borderRadius:'20px',width:'200px',color:'white',border:'1px solid white'}}
            variant='outlined'
            >
            Login
            </Button>
          </Link>
        </div>
      </div>
    )
  }
}

const mapStateToProps=(state)=>{
  return{
    token:state.app.token
  }
}

const mapDispatchToProps={

}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(SignUpView));
