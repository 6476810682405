const SET_USER = 'SET_USER';
const SET_TOKEN = 'SET_TOKEN';
const SET_APP_LOADING = 'SET_APP_LOADING';
const SET_PROFILE = 'SET_PROFILE';

export{
  SET_USER,
  SET_TOKEN,
  SET_APP_LOADING,
  SET_PROFILE
};
