import React, { Component } from 'react';
import{
  Typography,
  Button,
  IconButton,
  AppBar,
  Toolbar,
  Hidden,
  SwipeableDrawer,
} from '@material-ui/core';
import{
  Menu
} from '@material-ui/icons';
import {Link,withRouter} from 'react-router-dom';
import logo from '../images/Logo.png';
import {connect} from 'react-redux';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import BackupIcon from '@material-ui/icons/Backup';

const item={
  marginBottom:'30px',
  fontSize:'16px'
}
const link={
  textDecoration:'none'
}

class MobileBar extends Component{

  state={
    mobileDrawer:false
  }

  render(){
    const {setMobileDrawer,logOut} = this.props;

    return(
      <AppBar position='static' style={{background:'transparent'}}>
        <Toolbar style={{padding:'0px'}}>
          <IconButton
            onClick={()=>{this.setState({mobileDrawer:true})}}
            edge="start"
            style={{color:'black'}}
          >
            <Menu/>
          </IconButton>
          <div style={{marginLeft:'10px'}}>
            <Link to='/' style={{textDecoration:'none'}}>
              <img src={logo} alt={logo} style={{height:'30px',width:'auto'}}/>
            </Link>
          </div>
          <div style={{marginLeft:'auto'}}>
            <Link to='/myAccount' style={{textDecoration:'none'}}>
              <Button >Account</Button>
            </Link>
          </div>
        </Toolbar>
        <SwipeableDrawer
          anchor="left"
          open={this.state.mobileDrawer}
          onClose={()=>{this.setState({mobileDrawer:false})}}
          onOpen={()=>{this.setState({mobileDrawer:true})}}
        >
          <div style={{width:'250px',padding:'20px'}}>
            <img src={logo} alt={logo} style={{height:'30px',width:'auto',marginBottom:'40px'}}/>
            <Link style={link} to='/all'>
              <Button
              onClick={()=>{this.setState({mobileDrawer:false})}}
              >
                <Typography style={item}>All Documents</Typography>
              </Button>
            </Link>
            <Link style={link} to='/myDocuments'>
              <Button
              onClick={()=>{this.setState({mobileDrawer:false})}}
              >
                <Typography style={item}>Shared By Me</Typography>
              </Button>
            </Link>
            <Link style={link} to='/shared'>
              <Button
              onClick={()=>{this.setState({mobileDrawer:false})}}
              >
                <Typography style={item}>Shared With Me</Typography>
              </Button>
            </Link>
            <Button
            variant='outlined'
            color='primary'
            onClick={logOut}
            >
            Log Out
            </Button>
          </div>
        </SwipeableDrawer>
      </AppBar>
    )
  }
}

const mapStateToProps=(state)=>{
  return{
  }
}

const mapDispatchToProps={

}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(MobileBar));
