const url = process.env.REACT_APP_EMAIL_URL;

const createSubscription = async(email,paymentMethod,plan)=>{
  try{
    const result = await fetch(url + '/createSubscription',{
      method:'POST',
      headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        paymentMethod: paymentMethod,
        plan: plan
      })
    })
    const jsonResult = await result.json();
    if(!result.ok){
        console.log(jsonResult);
    }
    return jsonResult;
  }catch(err){
    console.log(err);
    return null;
  }
}

const createProfile = async(token,customerId,subscriptionId,planId,name,company,txLimit,signingAgent)=>{
  try{
    const result = await fetch(url + '/createProfile',{
      method:'POST',
      headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':token
      },
      body: JSON.stringify({
        customerId: customerId,
        subscriptionId: subscriptionId,
        planId: planId,
        name: name,
        company: company,
        txLimit: txLimit,
        signingAgent: signingAgent
      })
    })
    const jsonResult = await result.json();
    if(!result.ok){
        console.log(jsonResult);
    }
    return jsonResult;
  }catch(err){
    console.log(err);
    return null;
  }
}

const incrementTx = async(token)=>{
  try{
    const result = await fetch(url + '/incrementTx',{
      method:'POST',
      headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':token
      },
      body: JSON.stringify({
      })
    })
    const jsonResult = await result.json();
    if(!result.ok){
        console.log(jsonResult);
    }
    return jsonResult;
  }catch(err){
    console.log(err);
    return null;
  }
}

export {createSubscription, createProfile, incrementTx};
