import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

class DocumentView extends Component{

  render(){

    const paperStyle={
      width: '600px',
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      marginTop:'20px',
      padding:'10px'
    }
    const labelStyle={
      marginTop:'20px',
      fontSize:'120%'
    }
    const dataStyle={
      marginTop:'20px'
    }
    const timeStamp = new Date(this.props.contractTimeStamp * 1000);

    return(
      <Paper style={paperStyle}>
        <Typography style={labelStyle}>Document Hash:</Typography>
        <Typography style={dataStyle}>{this.props.contractHash}</Typography>
        <Typography style={labelStyle}>Email Address:</Typography>
        <Typography style={dataStyle}>{this.props.contractEmail}</Typography>
        <Typography style={labelStyle}>Public Key:</Typography>
        <Typography style={dataStyle}>{this.props.contractPublicKey}</Typography>
        <Typography style={labelStyle}>Upload Timestamp:</Typography>
        <Typography style={dataStyle}>{timeStamp.toLocaleString()}</Typography>
      </Paper>
    )
  }
}

export default DocumentView;
