import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

class EmailView extends Component{
  state={
    recipients:['']
  }

  goBack=()=>{
    this.setState({recipients:['']});
    this.props.unconfirm();
  }

  handleChange = index => event => {
    // this.setState({
    //   recipients: event.target.value,
    // });
    let newRecipients = this.state.recipients;
    newRecipients[index] = event.target.value;
    this.setState({recipients: newRecipients});
  };

  handleAdd =()=>{
    let newRecipients = this.state.recipients;
    newRecipients.push('');
    this.setState({recipients: newRecipients});
  }

  handleRemove=(index)=>{
    let newRecipients = this.state.recipients;
    newRecipients.splice(index,1);
    this.setState({recipients: newRecipients});
  }

  validateEmail=(email)=> {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  continue=()=>{
    let formatted = true;
    this.state.recipients.forEach(recipient=>{
      if(!this.validateEmail(recipient)){
        formatted = false;
      }
    })
    if(formatted){
      this.props.setRecipients(this.state.recipients.join(','));
    }else{
      alert('Please verify all email addresses are properly formatted');
    }
  }

  render(){
    const outerStyle={
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
    }
    const bottomStyle={
      height:'50px',
      marginTop:'10px',
      position:'fixed',
      bottom:0,
      width:'100%',
      display:'flex',
      flexDirection:'row',
      justifyContent:'flex-end',
      alignItems:'center',
    }
    const cardStyle={
      marginTop:'20px',
      width:'600px',
      padding:'20px'
    }

    const actions={
      marginTop:'20px',
      width:'100%',
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      justifyContent:'center',
      marginBottom:'100px'
    }

    const inputs = this.state.recipients.map((item,index)=>{
      return(
        <div style={{display:'flex'}} key={index}>
          <TextField
          style={{marginTop:'20px',marginBottom:'20px',width:'100%'}}
          variant='outlined'
          type='email'
          onChange={this.handleChange(index)}
          placeholder='email@example.com'
          value={item}/>
          {index !== 0 &&
            <Button
            onClick={()=>{this.handleRemove(index)}}
            >
            Remove
            </Button>
          }
        </div>
      )
    })
    return(
      <div>
      <div style={outerStyle}>
        <Typography style={{marginTop:'20px',fontSize:'150%'}}>Step 3: List emails to receive upload receipts</Typography>
        <Paper style={cardStyle}>
          <Typography style={{fontSize:'130%'}}>Recipients</Typography>
          <div style={{height:'100%',width:'100%',display:'flex',flexDirection:'column'}}>
            {inputs}
          </div>
          <Button
          color='primary'
          onClick={this.handleAdd}
          >
          Add Recipient
          </Button>
        </Paper>
      </div>
      <div style={actions}>
          <Button size='large' onClick={this.continue} variant='contained' color='primary'>Continue</Button>
          <Button style={{marginTop:'20px'}} onClick={this.goBack}>Back</Button>
      </div>
      </div>
    );
  }
}

export default EmailView;
