import React, { Component } from 'react';
import {Redirect,withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Paper,Typography,TextField,Button,InputLabel} from '@material-ui/core';
import style from '../styles/subscriptionStyle';
import DefaultTopBar from '../subscription/DefaultTopBar';

class ResetView extends Component{

  state={
    email:'',
    disabled:false,
  }

  validateEmail=(email)=> {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  handleEmail=()=>{
    if(this.validateEmail(this.state.email)){
      this.props.auth.sendPasswordResetEmail(this.state.email)
      .then(() => {
        this.setState({disabled:true});
      })
      .catch((error) => {
        var errorMessage = error.message;
        alert(errorMessage);
      });
    }else{
      alert('Please verify email address is properly formatted');
    }
  }

  render(){

    return(
      <div>
      <DefaultTopBar/>
      <div style={style.container}>
        <div style={style.checkoutContainer}>
          <Typography variant='h5'>Forgot Password?</Typography>
          <Typography>Enter the email you signed up with and we'll send a password reset link.</Typography>
          <TextField id='email' label='Email Address'
           type='email' value={this.state.email} onChange={ev=>{this.setState({email:ev.target.value})}}
           margin='dense' disabled={this.state.disabled}/>
           <Button
           disabled={this.state.disabled}
           onClick={this.handleEmail}
           variant='contained'
           color='secondary'
           style={{marginTop:'40px',borderRadius:'20px',width:'200px'}}
           >
           Submit
           </Button>
           {this.state.disabled &&
             <Typography style={{marginTop:'40px'}}>Please check your email</Typography>
           }
        </div>
      </div>

      </div>

    )
  }
}

const mapStateToProps=(state)=>{
  return{

  }
}

const mapDispatchToProps={

}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ResetView));
