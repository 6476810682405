import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Checkbox
} from '@material-ui/core';

class BlockchainView extends Component{

  state={
    uploading:false
  }

  render(){

    const {
      network,
      handleBlockchainSelect,
      blockchainSelected
    } = this.props;

    const disconnected = network === 'disconnected';

    const outerStyle={
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      marginBottom:'100px'
    }

    const cardStyle={
      marginTop:'20px',
      width:'600px',
      padding:'10px'
    }
    const dataStyle={
      padding:'10px'
    }
    const labelStyle={
      fontSize:'110%',
      marginBottom:'10px'
    }
    const infoStyle={
      marginBottom:'10px'
    }

    const actions={
      height:'160px',
      width:'400px',
      margin:'20px',
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center',
      border:'1px solid lightgrey',
      paddingBottom:'10px'
    }

    const actionContainer={
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      width:'100%'
    }

    const selectStyle={
      display:'flex',
      alignItems:'center'
    }

    const handleUpload=()=>{
      this.setState({uploading:true});
      this.props.deploy();
    }

    return(
      <div>
        <div style={outerStyle}>
          <Typography style={{marginTop:'20px',fontSize:'150%'}}>Step 4: Upload to Safebox</Typography>
          <Paper style={cardStyle}>
            <Typography style={{marginTop:'10px',fontSize:'130%'}}>Secure and Immutable</Typography>
            <hr/>
            <div style={dataStyle}>
              <Typography style={labelStyle}>Uploading will create a secure record on the Ethereum blockchain</Typography>
              <Typography style={labelStyle}>Blockchain data is immutable and tamper proof</Typography>
              <div style={selectStyle}>
                <Checkbox
                checked={blockchainSelected}
                onChange={handleBlockchainSelect}
                />
                <Typography>Add additional security with Blockchain</Typography>
              </div>
            </div>
          </Paper>
          <div style={actionContainer}>
            <div style={actions}>
                <Typography style={{marginBottom:'10px',fontSize:'130%'}}>Upload Now</Typography>
                <Button
                size='large'
                onClick={handleUpload}
                disabled={this.state.uploading}
                variant='contained' color='primary'
                >
                {
                  this.state.uploading ?
                  <CircularProgress size={20}/>
                  :
                  <Typography style={{color:'white'}}>Upload</Typography>
                }
                </Button>
            </div>
          </div>
          <Paper style={cardStyle}>
            <Typography style={{marginTop:'10px',fontSize:'130%'}}>File Details</Typography>
            <hr/>
            <div style={dataStyle}>
              <Typography style={labelStyle}>File Name</Typography>
              <Typography style={infoStyle}>{this.props.fileName}</Typography>
              <Typography style={labelStyle}>Uploader Email Address</Typography>
              <Typography style={infoStyle}>{this.props.email}</Typography>
              {!this.props.publicKey &&
                <Typography style={{marginBottom:'10px',color:'tomato'}}>Public Key not detected. Please unlock
                MetaMask and refresh page</Typography>
              }
            </div>
          </Paper>
          <div style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}>
            <Button style={{marginTop:'20px'}} onClick={()=>{this.props.setRecipients('')}}>Back</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default BlockchainView;
