import React, { Component } from 'react';
import {Redirect,withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import MyDocumentsView from '../document/MyDocumentsView';
import DocumentContainer from '../document/DocumentContainer';

class SharedContainer extends Component{

  state={
    selectedDocument: null
  }

  selectDocument=(file)=>{
    this.setState({selectedDocument:file});
  }

  componentDidUpdate=(prevProps)=>{
    if(JSON.stringify(prevProps.shared) !== JSON.stringify(this.props.shared)){
      if(this.state.selectedDocument){
        const doc = this.props.shared.find(item => item.uid === this.state.selectedDocument.uid);
        if(doc){
          this.setState({selectedDocument: doc});
        }
      }
    }
  }

  render(){
    if(!this.props.token){
      return <Redirect exact to='/login'/>
    }

    return(
      this.state.selectedDocument ?
      <DocumentContainer
      doc={this.state.selectedDocument}
      selectDocument={this.selectDocument}
      />
      :
      <MyDocumentsView
      myDocuments={this.props.shared}
      selectDocument={this.selectDocument}
      title={'Shared With Me'}
      />
    )
  }
}

const mapStateToProps=(state)=>{
  return{
    token: state.app.token,
    shared: state.documents.shared
  }
}

const mapDispatchToProps={

}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(SharedContainer));
