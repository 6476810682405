import React from 'react';
import {Typography} from '@material-ui/core';
import style from '../styles/subscriptionStyle';
import {Link} from 'react-router-dom';

const SubscriptionConfirm=(props)=>{

  return(
    <div style={style.container}>
      <Typography variant='h5' style={{margin:'20px'}}>
        Sign Up Successful!
      </Typography>
      <Typography variant='h5' style={{margin:'20px'}}>
        Please sign in to continue
      </Typography>
      <Link to='/login'>
        <Typography>Sign In</Typography>
      </Link>
    </div>
  )

}

export default SubscriptionConfirm;
