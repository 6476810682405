import React, { Component } from 'react';
import LogInView from './LogInView';
import {Redirect,withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

class LogInContainer extends Component{
  state={email:'',password:''}

  handleLogIn = async()=>{
    try{
      const result = await this.props.auth.signInWithEmailAndPassword(this.state.email,
      this.state.password)
      if(result){
        this.setState({loggedIn:true})
      }
    }catch(err){
      alert('LogIn Error:'+err);
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleKeyPress = event=>{
    if(event.key === 'Enter'){
      if(this.state.email.length && this.state.password.length){
        this.handleLogIn();
      }
    }
  }

  render(){
    if(this.props.token){
      return <Redirect exact to='/dashboard'/>
    }

    return(
      <LogInView
      email={this.state.email}
      password={this.state.password}
      handleLogIn={this.handleLogIn}
      handleChange={this.handleChange}
      handleKeyPress={this.handleKeyPress}
      />
    )
  }
}

const mapStateToProps=(state)=>{
  return{
    token:state.app.token
  }
}

const mapDispatchToProps={

}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(LogInContainer));
