import React, { Component } from 'react';
import MyAccountView from './MyAccountView';
import {connect} from 'react-redux';
import {Redirect,withRouter} from 'react-router-dom';
import {initUser,unsubscribe} from './user';

class MyAccountContainer extends Component{

  state={
    loading:false
  }

  handleUnsubscribe=async()=>{
    this.setState({loading:true});
    if(this.props.profile){
      const result = await this.props.unsubscribe(this.props.token,this.props.profile.subscriptionId);
      if(result.error){
        alert(result.error);
      }
    }
    this.setState({loading:false});
  }

  render(){
    if(!this.props.token){
      return <Redirect exact to='/login'/>
    }

    return(
      <MyAccountView
      handleUnsubscribe={this.handleUnsubscribe}
      profile={this.props.profile}
      loading={this.state.loading}
      logOut={this.props.logOut}
      />
    )
  }
}

const mapStateToProps=(state)=>{
  return{
    profile: state.app.profile,
    token: state.app.token,
  }
}

const mapDispatchToProps={
  initUser,
  unsubscribe
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(MyAccountContainer));
