import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import style from '../styles/tableStyle';
import Loading from '../notification/Loading';
import CustomTableHead from './CustomTableHead';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class CustomTable extends Component{
  state={
    order:'desc',
    orderBy:'name',
    selected:null,
    data: this.props.data,
    loading:false
  }

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = 'desc';
		if(property && this.state.order === 'desc') {
			order = 'asc';
		}
		this.setState({ order, orderBy });
	};

	handleClick=(id)=>{
		this.setState({selected:id})
		this.props.selectItem(id);
	}

	isSelected = (id) => {
		return(
			this.state.selected === id
		)
	}

	componentDidUpdate=(prevProps)=>{
		if(JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)){
			this.setState({data:this.props.data})
		}
	}

	render(){
		const {data,order,orderBy} = this.state;
		return(
			this.state.loading
			?
			<Loading/>
			:
			<div >
				<div style={style.tableBody}>
					<Table>
              <CustomTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              columns={this.props.columns}
              />
							<TableBody>
									{stableSort(data,getSorting(order,orderBy))
										.map(item=>{
											const isSelected = this.isSelected(item.id);
											return(
                        this.props.tableRow(item,isSelected,this.handleClick)
											)
										})
									}
								</TableBody>
							</Table>
						</div>
					</div>
		)
	}
}

export default CustomTable;
