const style={
  form:{
    width:'350px',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems: 'center',
    backgroundColor:'white',
    borderRadius:'5px',
    padding:'20px 0px'
  },
  header:{
    marginBottom:'20px'
  },
  container:{
    width:'100%',
    display:'flex',
    justifyContent:'space-around',
  },
  page:{
    backgroundImage:'linear-gradient(to right,#2677d4,#5c97db)',
    paddingTop:'40px',
    height:'95vh'
  },
  logoContainer:{
    backgroundColor:'white',
    width:'200px',
    padding:'10px',
    borderRadius:'0px 5px 5px 0px'
  }
}

export default style;
