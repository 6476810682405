import React, {Component} from 'react';
import ShareDialog from './ShareDialog';
import DocumentView from './DocumentView';
import {updateShared,initDocuments} from './document';
import {connect} from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

const outerStyles = theme =>({
  container:{
    [theme.breakpoints.down('sm')]:{
      padding:'20px'
    },
    [theme.breakpoints.up('sm')]:{
      padding:'40px 80px'
    }
  },
})

class DocumentContainer extends Component{

  state={
    shareOpen: false,
    shared : this.props.doc.shared ? this.props.doc.shared.split(',') : [],
  }

  openShare=()=>{
    this.setState({shareOpen : true});
  }

  closeShare=()=>{
    this.setState({shareOpen : false});
  }

  handleSave= async(shared)=>{
    const sharedString = shared.join(',');
    const result = await updateShared(this.props.doc.uid,sharedString);
    if(result.data){
      this.props.initDocuments(this.props.token);
    }
    this.setState({shareOpen : false});
  }

  componentDidUpdate=(prevProps)=>{
    if(this.props.doc.shared !== prevProps.doc.shared){
      this.setState({shared : this.props.doc.shared.split(',')})
    }
  }

  render(){
    const {classes} = this.props;

    return(
      <div className={classes.container}>
        <DocumentView
        shareOpen={this.state.shareOpen}
        openShare={this.openShare}
        closeShare={this.closeShare}
        handleSave={this.handleSave}
        doc={this.props.doc}
        selectDocument={this.props.selectDocument}
        user={this.props.user}
        />
        {
          this.state.shareOpen &&
          <ShareDialog
          shareOpen={this.state.shareOpen}
          openShare={this.openShare}
          closeShare={this.closeShare}
          handleSave={this.handleSave}
          shared={this.state.shared}
          />
        }
      </div>
    )
  }
}

const mapStateToProps=(state)=>{
  return{
    token: state.app.token,
    myDocuments: state.documents.myDocuments,
    user: state.app.user
  }
}

const mapDispatchToProps={
  initDocuments
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(outerStyles)(DocumentContainer));
