import{
  SET_MY_DOCUMENTS,
  SET_SHARED,
  SET_MONTHLY_TX
} from '../action_types/documentActionTypes';

export const setMyDocuments = (myDocuments) =>{
  return{
    type: SET_MY_DOCUMENTS,
    myDocuments
  }
}

export const setShared = (shared) =>{
  return{
    type: SET_SHARED,
    shared
  }
}

export const setMonthlyTx = (monthlyTx)=>{
  return{
    type: SET_MONTHLY_TX,
    monthlyTx
  }
}
