const style={
  dashboardFrame:{
    padding:'0px 100px',
    backgroundColor: '#ececec',
    height:'100vh'
  },
  section:{
    marginBottom:'40px'
  },
  number:{
    color:'white'
  },
  uploadContainer:{
    marginLeft:'200px',
    border:'1px solid lightgrey',
    height:'250px',
    width:'60%',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center'
  },
  icon:{
    height:'100px',
    width:'auto',
  },
  card:{
    padding:'40px',
    backgroundColor:'#0063a8'
  },
  cardText:{
    color:'white',
    margin:'20px 0px'
  }
}

export default style;
