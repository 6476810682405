import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

class ConfirmView extends Component{

  render(){
    const previewStyle = {
      display: 'inline',
      width: '800px',
      height:'600px',
      marginTop:'20px'
    };
    const outerStyle={
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
    }

    const actions={
      marginTop:'20px',
      width:'100%',
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      justifyContent:'center',
      marginBottom:'100px'
    }

    return(
      <div>
        <div style={outerStyle}>
          <Typography style={{marginTop:'20px',fontSize:'150%'}}>Step 2: Confirm the document details</Typography>
          <embed
            src={this.props.file.preview}
            style={previewStyle}
          />
        </div>
        <div style={actions} elevation={2}>
            <Button size='large' onClick={this.props.confirm} variant='contained' color='primary'>Continue</Button>
            <Button style={{marginTop:'20px'}} onClick={this.props.clearAccepted}>Back</Button>
        </div>
      </div>
    );
  }
}

export default ConfirmView;
