import React from 'react';
import CustomTable from '../table/CustomTable';
import {TableRow,TableCell,Typography,Hidden,List,ListItem,ListItemText,ListItemIcon} from '@material-ui/core';
import style from '../styles/documentStyle';
import BackIcon from '@material-ui/icons/ArrowBack';
import {Link} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {Description} from '@material-ui/icons';

const outerStyles = theme =>({
  myDocumentsFrame:{
    [theme.breakpoints.down('sm')]:{
      padding:'20px'
    },
    [theme.breakpoints.up('sm')]:{
      padding:'40px 80px'
    }
  },
})

const MyDocumentsView =(props)=>{

  const tableRow=(file, isSelected, handleClick)=>{
    const date = new Date(file.timestamp);

    return (
      <TableRow key={file.name}  onClick={event=>handleClick(file)} hover>
        <TableCell padding='none' align='center'>{file.name}</TableCell>
        <TableCell padding='none' align='center'>{file.ownerEmail}</TableCell>
        <TableCell padding='none' align='center'>{date.toLocaleString()}</TableCell>
        <TableCell padding='none' align='center'>{file.shared}</TableCell>
      </TableRow>
    )
  }

  const columns=[
    {id: 'name', numeric: true, label: 'Name'},
    {id: 'ownerEmail', numeric: false, label: 'Owner'},
    {id: 'timestamp', numeric : true, label: 'Upload Date'},
    {id: 'shared', numeric: false, label: 'Shared With'}
  ]

  const {classes} = props;

  const sorted = props.myDocuments.sort(function(a,b){
    return b.timestamp - a.timestamp
  })

  return(
    <div className={classes.myDocumentsFrame}>
      <Link to='/dashboard' style={{textDecoration:'none',color:'black'}}>
        <BackIcon/>
      </Link>
      <Typography
      variant='h4'
      style={{marginBottom:'40px',marginTop:'20px'}}
      >{props.title}</Typography>
      <Hidden smDown>
        <CustomTable
        data={props.myDocuments}
        selectItem={props.selectDocument}
        tableRow={tableRow}
        columns={columns}
        />
      </Hidden>
      <Hidden smUp>
        <List>
          {
            sorted.map((item,index)=>
              <ListItem button divider key={index} onClick={()=>{props.selectDocument(item)}}>
                <ListItemIcon><Description/></ListItemIcon>
                <ListItemText
                primary={item.name}
                secondary={new Date(item.timestamp).toLocaleString()}
                />
              </ListItem>
            )
          }
        </List>
      </Hidden>
    </div>
  )
}

export default withStyles(outerStyles)(MyDocumentsView)
