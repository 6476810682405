import React, { Component } from 'react';
import {
  Typography,
  Button
} from '@material-ui/core';
import {Link} from 'react-router-dom';

class ConfirmationView extends Component{

  render(){
    return(
      <div style={{width:'100%', display:'flex',flexDirection:'column',alignItems:'center'}}>
        <Typography variant='h5' style={{marginTop:'40px'}}>Subscription Successful!</Typography>
        <Typography variant='h6' style={{marginTop:'20px'}}>Click below to view your dashboard</Typography>
        <Link to='/dashboard' style={{textDecoration:'none'}}>
          <Button
          variant='contained'
          color='primary'
          style={{marginTop:'40px'}}
          >
          Go to dashboard
          </Button>
        </Link>
      </div>
    )
  }
}

export default ConfirmationView;
