import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import Typography from '@material-ui/core/Typography';

class UploadView extends Component{

  render(){
    const outerStyle={
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      height:'400px'
    }

    const dropStyle={
      backgroundColor:'rgba(240,248,255,1)',
      width: '600px',
      height:'200px',
      borderWidth:'2px',
      borderColor:'rgba(102,102,102,1)',
      borderStyle: 'dashed',
      borderRadius: '5px',
      marginTop:'20px',
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      justifyContent:'center'
    }
    const acceptStyle={
      backgroundColor:'rgba(240,248,255,1)',
      width: '600px',
      height:'200px',
      borderWidth:'2px',
      borderColor:'lime',
      borderStyle: 'solid',
      borderRadius: '5px',
      marginTop:'20px',
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      justifyContent:'center'
    }
    const rejectStyle={
      backgroundColor:'rgba(237,200,220,1)',
      width: '600px',
      height:'200px',
      borderWidth:'2px',
      borderColor:'red',
      borderStyle: 'solid',
      borderRadius: '5px',
      marginTop:'20px',
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      justifyContent:'center'
    }
    const directionStyle={
      fontSize:'130%'
    }

    const dropMessage = this.props.rejected.length > 0 ?
      <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
        <Typography style={directionStyle}>The file could not be uploaded</Typography>
        <Typography style={directionStyle}>Please check that it is a valid type</Typography>
      </div>
      :
      <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
        <Typography style={directionStyle}>Drag and drop a file</Typography>
        <Typography style={directionStyle}>OR</Typography>
        <Typography style={directionStyle}>Click anywhere in this area</Typography>
      </div>
    return(
      <div>
        <section>
          <div style={outerStyle}>
            <Typography style={{marginTop:'20px',fontSize:'150%'}}>Step 1: Upload a document</Typography>
            <Dropzone accept={this.props.acceptedTypes} style={dropStyle} acceptStyle={acceptStyle}
              rejectStyle={rejectStyle} onDropAccepted={this.props.onDropAccepted} onDropRejected={this.props.onDropRejected}>
              {dropMessage}
            </Dropzone>
            <Typography style={{marginTop:'20px'}}>Please upload a document in PDF format.</Typography>
          </div>
        </section>
      </div>
    )
  }
}

export default UploadView;
