import React, {Component} from 'react';
import {
  Typography,
  Divider,
  TextField,
  Button,
  Checkbox,
  CircularProgress,
  Tooltip,
  IconButton,
  Hidden
} from '@material-ui/core';
import{
  Clear
} from '@material-ui/icons';
import SideBar from '../nav/SideBar';
import Dropzone from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';

const outerStyles = theme =>({
  container:{
    width:'100%',
    display:'flex',
    flexDirection:'column',
    [theme.breakpoints.down('sm')]:{
      padding:'0px 10px 0px'
    },
    [theme.breakpoints.up('sm')]:{
      marginLeft:'7%'
    }
  },
})

const style={
  outer:{
    display:'flex'
  },
  container:{
    width:'100%',
    display:'flex',
    flexDirection:'column',
    // alignItems:'center',
    border:'1px solid',
  },
  dropStyle:{
    backgroundColor:'rgba(240,248,255,1)',
    minWidth:'300px',
    maxWidth: '600px',
    height:'200px',
    borderWidth:'2px',
    borderColor:'rgba(102,102,102,1)',
    borderStyle: 'dashed',
    borderRadius: '5px',
    margin:'20px 10px 0px',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center'
  },
  acceptStyle:{
    backgroundColor:'rgba(240,248,255,1)',
    maxWidth: '600px',
    height:'200px',
    borderWidth:'2px',
    borderColor:'lime',
    borderStyle: 'solid',
    borderRadius: '5px',
    marginTop:'20px',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center'
  },
  rejectStyle:{
    backgroundColor:'rgba(237,200,220,1)',
    width: '600px',
    height:'200px',
    borderWidth:'2px',
    borderColor:'red',
    borderStyle: 'solid',
    borderRadius: '5px',
    marginTop:'20px',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center'
  },
  directionStyle:{
    fontSize:'130%'
  },
  uploadSection:{
    marginBottom:'40px'
  },
  emailSection:{
    margin:'40px 0px',
    maxWidth:'600px'
  },
  divider:{
    width:'80%'
  },
  submitSection:{
    margin:'40px 0px 100px 0px',
    maxWidth:'600px',
    // display:'flex',
    // flexDirection:'column',
    // alignItems:'center'
  },
  selectStyle:{
    display:'flex',
    alignItems:'center'
  },
  info:{
    color:'#2196f3',
    marginLeft:'10px'
  },
  popover:{
    padding:'10px'
  },
  metamask:{
    textDecoration:'none'
  },
  fileSection:{
    display:'flex',
    alignItems:'center',
    marginTop:'10px'
  },
  preview:{
    width:'600px',
    height:'300px'
  }
}

function SingleView(props){

  const{
    accepted,
    acceptedTypes,
    onDropAccepted,
    onDropRejected,
    rejected,
    recipients,
    setRecipients,
    network,
    handleBlockchainSelect,
    blockchainSelected,
    handleUpload,
    uploading,
    infoOpen,
    handleInfoOpen,
    clearAccepted,
    emailInput,
    handleEmailInput,
    classes
  } = props;

  const validateEmail=(email)=> {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const handleAdd =()=>{
    //check for accuracy
    if(validateEmail(emailInput)){
      let newRecipients = recipients;
      newRecipients.push(emailInput);
      setRecipients(newRecipients);
      handleEmailInput('');
    }else{
      alert('Please verify email address is properly formatted');
    }

  }

  const handleRemove=(index)=>{
    let newRecipients = recipients;
    newRecipients.splice(index,1);
    setRecipients(newRecipients);
  }

  const handleMouseEnter=(ev)=>{
    handleInfoOpen(ev.currentTarget)
  }

  const handleMouseLeave=()=>{
    handleInfoOpen(null);
  }

  const dropMessage = rejected.length > 0 ?
    <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
      <Typography style={style.directionStyle}>The file could not be uploaded</Typography>
      <Typography style={style.directionStyle}>Please check that it is a valid type</Typography>
    </div>
    :
    <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
      <Typography style={style.directionStyle}>Click anywhere in this area</Typography>
    </div>

    const emailItems = recipients.map((item,index)=>{
      return(
        <div style={{display:'flex',alignItems:'center'}} key={index}>
          <Typography>{item}</Typography>
          <IconButton
          onClick={()=>{handleRemove(index)}}
          >
            <Clear/>
          </IconButton>
        </div>
      )
    })

  const disabled = network === 'disconnected';

  const enabled = accepted.length >0 && recipients.length > 0 && !uploading;

  return(
  <div style={style.outer}>
    <Hidden smDown>
      <SideBar disabled={disabled}/>
    </Hidden>
    <div className={classes.container}>
      <section style={style.uploadSection}>
        <Typography variant='h5' style={{marginTop:'60px'}}>Upload a document</Typography>
        {
          accepted.length > 0 ?
          <div style={{marginTop:'20px'}}>
            <Hidden smDown>
              <embed src={accepted[0].preview} style={style.preview}/>
            </Hidden>
            <div style={style.fileSection}>
              <Typography>{accepted[0].name}</Typography>
              <IconButton
              onClick={clearAccepted}
              style={{marginLeft:'10px'}}
              >
                <Clear/>
              </IconButton>
            </div>
          </div>
          :
          <div>
          <Dropzone accept={acceptedTypes} style={style.dropStyle} acceptStyle={style.acceptStyle}
            rejectStyle={style.rejectStyle} onDropAccepted={onDropAccepted} onDropRejected={onDropRejected}>
            {dropMessage}
          </Dropzone>
          <Typography style={{marginTop:'20px'}}>Please select a PDF document or image (JPG or PNG format).</Typography>
          </div>
        }
      </section>
      <Divider style={style.divider}/>
      <section style={style.emailSection}>
        <Typography variant='h6'>Enter recipient emails</Typography>
        <div style={{display:'flex',alignItems:'center'}}>
          <TextField
          id='email'
          style={{marginTop:'10px',marginBottom:'10px',width:'200px'}}
          variant='outlined'
          type='email'
          onChange={(ev)=>{handleEmailInput(ev.target.value)}}
          placeholder='email@example.com'
          value={emailInput}
          />
          <Button
          onClick={handleAdd}
          variant='outlined'
          style={{marginLeft:'10px'}}
          >
          Save
          </Button>
        </div>
        <div style={{height:'100%',width:'100%',display:'flex',flexDirection:'column'}}>
          {emailItems}
        </div>
      </section>
      <Divider style={style.divider}/>
      <section style={style.submitSection}>
      {
        !disabled &&
        <Hidden smDown>
          <div style={style.selectStyle}>
            <Checkbox
            color='primary'
            checked={blockchainSelected}
            onChange={(ev)=>{handleBlockchainSelect(ev.target.checked)}}
            disabled={disabled}
            />
            <Typography>Create a secure Blockchain record of your transaction.</Typography>
            <Tooltip title='A Blockchain record is absolute proof that you possessed this document at a certain time. It cannot be faked or tampered with. SAFEBOX uses the Ethereum Blockchain.'>
              <Typography id='info' style={style.info}>What's this?</Typography>
            </Tooltip>
          </div>
        </Hidden>
      }
        <Button
        size='large'
        onClick={handleUpload}
        disabled={!enabled}
        variant='contained' color='primary'
        style={{marginTop:'40px'}}
        >
        {
          uploading ?
          <CircularProgress size={20}/>
          :
          <Typography style={{color:'white'}}>Upload</Typography>
        }
        </Button>
      </section>
    </div>
  </div>
  )
}

export default withStyles(outerStyles,{withTheme:true})(SingleView)
